<form class="mx-auto" #form="ngForm" (ngSubmit)="save(form)" novalidate>
  <div class="modal-header">
    <h4 class="modal-title text-primary">
      <i class="icon icon-pencil icon-lg"></i>Edit Profile</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="!isLoaded">
    <div class="text-center pt-5">
      <strong class="text-info">
        <i class="icon icon-light-up fast-right-spinner"></i> Loading...
      </strong>
    </div>
  </div>
  <div class="modal-body" *ngIf="isLoaded">

    <form-field label="First name">
      <input class="form-control" type="text" name="FirstName" required [(ngModel)]="dto.FirstName">
    </form-field>

    <form-field label="Last name">
      <input class="form-control" type="text" name="LastName" required [(ngModel)]="dto.LastName">
    </form-field>

    <form-field label="Company name">
      <input class="form-control" type="text" name="CompanyName" [(ngModel)]="dto.CompanyName">
    </form-field>

    <form-field label="Mobile phone">
      <input class="form-control" type="text" name="MobilePhone" [(ngModel)]="dto.MobilePhone">
    </form-field>

    <form-field label="Work phone">
      <input class="form-control" type="text" name="WorkPhone" [(ngModel)]="dto.WorkPhone">
    </form-field>

    <form-field>
      For security reasons, changes to your profile do not update your record with your Agency. Please contact your agency to inform them of the change.
    </form-field>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-primary float-right" type="button" (click)="activeModal.dismiss('cancel')">Cancel</button>
    <button [disabled]="isSaving || !form.valid" class="btn btn-outline-primary float-right" type="submit">Save</button>
  </div>
</form>

