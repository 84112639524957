import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GetConsentResponse, ScopeViewModel } from '@app/app.dtos';
import { Alert } from '../model/alert';
import { ConsentService, ScopeGroupViewModel } from './services/consent.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap/tooltip/tooltip-config';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap/tooltip/tooltip';
import { Compiler } from '@angular/core';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  constructor(
    private consentService: ConsentService,
    private route: ActivatedRoute,
    private router: Router,
    private compiler: Compiler
  ) {
    this.compiler.clearCache();
  }
  scopeGroups: ScopeGroupViewModel[];
  consent: GetConsentResponse;
  customerId: string;
  returnUrl: string;
  alert: Alert;
  loading: boolean = true;

  async ngOnInit() {
    
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.route.snapshot.queryParams['ReturnUrl'];
    await this.load();
  }

  async load() {
    let r = await this.consentService.getConsent(this.returnUrl);
    this.consent = r;
    if (r && r.portfolioInfo) {
      let selectedPortfolio = r.portfolioInfo.find(item => item.checked);
      if (selectedPortfolio) {
        this.customerId = selectedPortfolio.customerId;
      }
    }

    if (this.consent && this.consent.resourceScopes && this.consent.resourceScopes.length > 0) {
      this.scopeGroups = this.buildScopeGroups(this.consent.resourceScopes);
    }
    this.loading = false;
  }

  async process(isAllowing: boolean) {
    const that = this;

    let scopesConsented = that.consent
      .identityScopes
      .filter(item => item.checked)
      .map(item => item.name)
      .concat(that.consent.resourceScopes.filter(item => item.checked).map(item => item.name));

    try {
      var response = await that.consentService.processConsent(isAllowing, scopesConsented, that.returnUrl, that.customerId);
      if (response.redirectUri.startsWith('/') && !response.redirectUri.startsWith('/connect')) {
        await this.router.navigateByUrl(response.redirectUri);
      } else {
        window.location.href = response.redirectUri;
      }
    } catch (error) {
      if (error.responseStatus && error.responseStatus.errorCode) {
        if (error.responseStatus.errorCode == 'ArgumentException') {
          that.alert = new Alert(error.responseStatus.message, 'danger');
          return;
        }
      }
      // let default error handler handle unknown errors.
      throw error;
    }
  }

  public buildScopeGroups(resourceScopes: ScopeViewModel[]) {
    
    const scopesWithDescription =  resourceScopes.filter(s => s.description && s.displayName.indexOf('-') > 0);
    
    const scopesWithoutDescription = resourceScopes.filter(s => !s.description || s.displayName.indexOf('-') <= 0);
    
    const scopeNames = scopesWithDescription.map(s => s.name.split(':')[0]);

    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    
    const uniqueScopeNames = scopeNames.filter(unique);

    let scopeGroups = [];
    let that = this;
    uniqueScopeNames.forEach(function(scope) {
      const group = that.buildScopeGroup(scope, resourceScopes.filter(s => s.name.startsWith(scope)  ));
      scopeGroups.push(group)
    });
    
    
    if (scopesWithoutDescription && scopesWithoutDescription.length > 0){
      const otherGroup = this.buildOtherGroup(scopesWithoutDescription);
      scopeGroups.push(otherGroup);
    }

    return scopeGroups.filter(s => s.scopes && s.scopes.length > 0);
  }

  buildScopeGroup(type: string, scopes: ScopeViewModel[]) {
    if (!scopes || scopes.length === 0){
      return;
    }

    const isReadAndWrite = scopes && scopes.length === 2;
    let scopeName = scopes[0].displayName.split('-')[0].trim();
    const name = isReadAndWrite ? scopeName + ' - Read & Write' : scopes[0].displayName;
    const description = isReadAndWrite ? scopes[0].description + ' ' + scopes[1].description : scopes[0].description;

    return new ScopeGroupViewModel({
      name: name,
      description: description,
      scopes: scopes
    });
  }
  
  buildOtherGroup(scopes: ScopeViewModel[]) {
    return new ScopeGroupViewModel({
      name: 'Other',
      description: scopes.map(s => s.displayName).join('<br>'),
      scopes: scopes
    });
  }
}
