<div *ngIf="!loading" class="container-fluid d-flex justify-content-center p-2">
  <div class="card">
    <form class="mx-auto p-4"
          name="form" (ngSubmit)="f.form.valid" #f="ngForm">
      <div class="text-center mb-3">
        <img class="non-retina" src="assets/img/brand.png" style="height:100px" alt="PropertyMe">
        <img class="retina" src="assets/img/brand-big.png" style="height:100px" alt="PropertyMe">
        <h2>{{consent.clientName}} </h2><div><strong>is requesting permission to view or make changes to your data</strong></div>
      </div>
      <div *ngIf="consent && consent.identityScopes && consent.identityScopes.length > 0" class="form-group mb-3">
        <div class="card">
          <div class="card-header">
            <i class="fas fa-user"></i>
            Personal Information
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let item of consent.identityScopes">
              <div class="form-check">
                <label>
                  <input class="consent-scopecheck form-check-input"
                         type="checkbox"
                         [checked]="item.checked"
                         [disabled]="item.required"
                         (change)="item.checked = !item.checked" />
                  <strong>{{item.displayName}}</strong>
                  <i *ngIf="item.emphasize" class="mx-2 fas fa-exclamation-circle"></i>
                </label>
                <span *ngIf="item.required"><em>(required)</em></span>
                <div *ngIf="item.description" class="consent-description">
                  <label for="{{item.name}}">{{item.description}}</label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="consent && consent.portfolioInfo && consent.portfolioInfo.length > 0" class="form-group mb-3">
        <div class="card">
          <div class="card-header">
            <i class="fas fa-book"></i>
            Which portfolio would you like to allow this integrator to access?
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let item of consent.portfolioInfo">
              <div class="form-check">
                <label>
                  <input class="consent-scopecheck form-check-input"
                         type="radio"
                         name="portfolioInfo"
                         [value]="item.customerId"
                         [(ngModel)]="customerId" />
                  <strong>{{item.displayName}}</strong>
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <ng-template #tipContent><p class="consent-tooltip">Allowing Read only access means the requestor can view data in your portfolio. Allowing Read & Write access means the integrator can view and/or make changes to your data in your portfolio for the nominated areas.</p>  <p class="consent-tooltip">Before granting permission ensure you trust the requestor. Contact them directly if you need more information on how they will use your data.</p> </ng-template>
      <div *ngIf="consent && consent.resourceScopes && consent.resourceScopes.length > 0" class="form-group mb-3">
        <div class="card">
          <div class="card-header">
            <i class="fas fa-tasks"></i>
            The integrator is requesting access to the following information from your portfolio <i class="fas fa-question-circle" [ngbTooltip]="tipContent" placement="right-top"></i>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let group of scopeGroups">
              <div class="form-check">
                <h4 class="modal-title text-primary group-title">{{group.name}}</h4>
                <div class="consent-description">
                  <p [innerHTML]="group.description"></p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <ngb-alert *ngIf="alert" [type]="alert.type" (close)="alert = null">
        <div class="card-text card-smaller-text  text-left">
          <i class="fas fa-exclamation-circle"></i> {{alert.message}}
        </div>
      </ngb-alert>
      <div class="d-flex justify-content-between">
        <button name="button" value="no" class="btn btn-primary" (click)="process(false)">No, Do Not Allow</button>
        <button name="button" value="yes" class="btn btn-primary" autofocus (click)="process(true)">Yes, Allow</button>
      </div>
    </form>
  </div>
</div>
