<div class="container pt-4 mt-5">

  <h3><strong>About</strong></h3>

  <div class="row">
    <div class="col-lg-6">

      <div class="card-body">

          <p>
            Pull up a chair and have a listen to our story.
          </p>
          <p>
            Having previously dabbled in the software game creating one of Australia’s largest property management software companies, the founders of PropertyMe have years of experience building and working on property accounting software under their belts.
          </p>
          <p>
            The company was sold and they went on their different journeys, however after a few years, they noticed that nothing was progressing within the PM software space, as the same legacy products were still there with not much innovation happening. Not ones to leave an itch unscratched, they jumped right back into the software space to bring a fresh new approach to property accounting software. One that’s easy to use, affordable and accessible.
          </p>
          <hr>
          <p>© 2018 PropertyMe, Inc. <a target="_blank" href="http://www.propertyme.com.au">www.propertyme.com.au</a> <span class="version">v {{version}}</span></p>

      </div>
    </div>

    <div class="col-lg-6">
      <img width="500" src="/assets/img/Logo-trans-1200px.png" class="img-responsive" alt="" sizes="(max-width: 500px) 100vw, 500px">
    </div>


  </div>
</div>
