import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { RouterExtService } from '@_services/router-ext.service';
import { ErrorService } from './services/error.service';
import { ErrorMessage } from '@app/app.dtos';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})

export class ErrorComponent implements OnInit {
  errorId: string;
  error: ErrorMessage = null;
  redirectMsg: string;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private errorService: ErrorService) {
  }

  async ngOnInit() {
    const that = this;
    that.errorId = that.route.snapshot.queryParams['errorId'] || '';

    try {
      if (this.errorId) {
        let errorResponse = await that.errorService.getErrorDetails(that.errorId);
        if (errorResponse.error && errorResponse.error.redirectUri) {
          countDown(5, errorResponse.error.redirectUri);
        }
        that.error = errorResponse.error;
      }
    } catch (e) {

    }

    function countDown(i: number, returnUrl: string) {
      var int = setInterval(function () {
        that.redirectMsg = `You will be redirected to "${returnUrl}" in ${i} second${i > 1 ? 's' : ''}`;
        i--;
        if (i < 0) {
          clearInterval(int);
          window.location.href = returnUrl;
        }
      }, 1000);
    }
  }

}
