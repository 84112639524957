<div class="container h-100 d-flex justify-content-center">
  <div class="d-flex flex-column my-auto">
    <form autocomplete="off"
          class="mx-auto p-4 card d-flex flex-column"
          id="setup-tfa-form"
          (ngSubmit)="f.form.valid && setupTfa()"
          [ngClass]="{'was-validated': f.submitted}"
          name="form" #f="ngForm">
      <div>
        <div class="text-center">
          <img class="non-retina mx-auto" src="assets/img/brand.png" style="height:80px" alt="PropertyMe">
          <img class="retina mx-auto" src="assets/img/brand-big.png" style="height:80px" alt="PropertyMe">
        </div>
      </div>
      <div class="form-group" [ngClass]="{ 'has-danger' : f.submitted }">
        <h4 class="mt-4">Set up Two-Factor Authentication</h4>
        <div *ngIf="step === 0">
          <ul class="my-4">
            <li>Before you proceed, you are required to set up two-factor authentication for extra security.</li>
          </ul>
        </div>
        <div *ngIf="step === 1 && !isShowingSecret">
          <ul>
            <li>Get the Google Authenticator App from the <a class="white underline" href="https://itunes.apple.com/au/app/google-authenticator/id388497605?mt=8" target="_blank">App Store</a> or Android <a class="white underline" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">Play Store</a>.</li>
            <li>In the App, tap the <strong>Add</strong> button in the navigation bar.</li>
            <li>Choose <strong>Scan barcode</strong>.</li>
          </ul>
          <div class="text-center">
            <qr-code [value]="" [size]="150"></qr-code>
            <p><button id="cantScanButton" class="btn btn-link white" (click)="showSecret()">CAN'T SCAN IT?</button></p>
          </div>
        </div>
        <div *ngIf="step === 1 && isShowingSecret">
          <ol>
            <li>In the App, tap the <strong>Add button</strong> in the navigation bar.</li>
            <li>Choose <strong>Manual entry</strong>.</li>
            <li>
              Enter your email address and this key:
              <div class="text-center my-2 secret-text">
                <div class="alert alert-info tfa-secret-display-box">
                  <h3 class="number">{{secretForDisplay()}}</h3>
                </div>
                <div>space don't matter</div>
              </div>
            </li>
            <li>Make sure that <strong>Time based</strong> is turned on, and tap <strong>Add</strong> to finish.</li>
          </ol>
        </div>
        <div *ngIf="step === 2">
          <p>Please enter 6-digit code from the Google Authenticator app for verification</p>
          <input autocomplete="off" type="tel" class="form-control" name="OneTimePinCode" [(ngModel)]="oneTimePinCode" #input="ngModel" required placeholder="6-digit number" maxlength="6" />
          <div *ngIf="f.submitted && !input.valid" class="invalid-feedback">6-digit code required</div>
        </div>
      </div>
      <ngb-alert *ngIf="alert" [type]="alert.type" (close)="alert = null">
        <div class="card-text card-smaller-text text-left">
          <i class="fas fa-exclamation-circle"></i> {{alert.message}}
        </div>
      </ngb-alert>

      <div class="mt-auto mb-2 d-flex justify-content-between">
        <a class="my-auto" href="" (click)="back()">Back <span *ngIf="step <= 0">to sign in</span></a>
        <button [disabled]="loading" class="btn btn-primary justify-content-space-between" type="button" (click)="next()">{{step === 2 ? 'Verify' : 'Next' }}</button>
      </div>
    </form>
    <copyright class="mb-2"></copyright>
  </div>
</div>
