<div class="container h-100 d-flex justify-content-center">
  <div class="d-flex flex-column my-auto">
    <form autocomplete="off"
          class="mx-auto p-4 card d-flex flex-column"
          id="forgotten-password-form"
          (ngSubmit)="f.form.valid && forgottenPassword()"
          [ngClass]="{'was-validated': f.submitted}"
          name="form" #f="ngForm">
      <div>
        <div class="text-center">
          <img class="non-retina mx-auto" src="assets/img/brand.png" style="height:80px" alt="PropertyMe">
          <img class="retina mx-auto" src="assets/img/brand-big.png" style="height:80px" alt="PropertyMe">
        </div>
      </div>
      <div class="form-group" [ngClass]="{ 'has-danger' : f.submitted && !username.valid }">
        <h4 class="mt-4">Forgotten Password</h4>
        <p>Enter your email address to receive instructions</p>
        <input autocomplete="off" type="text" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel" required placeholder="Email" />
        <div *ngIf="f.submitted && !username.valid" class="invalid-feedback">Email is required</div>
      </div>
      <ngb-alert *ngIf="alert" [type]="alert.type" (close)="alert = null">
        <div class="card-text card-smaller-text  text-left">
          <i class="fas fa-exclamation-circle"></i> {{alert.message}}
        </div>
      </ngb-alert>

      <div class="mt-auto mb-2 d-flex justify-content-between">
        <a class="my-auto" routerLink="/sign-in" [queryParams]="{returnUrl: returnUrl}">Back to sign in</a>
        <button [disabled]="loading" class="btn btn-primary justify-content-space-between" type="submit">Email Me</button>
      </div>
    </form>
    <copyright class="mb-2"></copyright>
  </div>
</div>
