  <div >
    <div class="modal-header">
      <h4 class="modal-title"><i class="icon icon-thunder-cloud icon-lg"></i> {{model ? model.title : ''}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{model ? model.message : ''}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">Close</button>
    </div>
  </div>

