<div class="modal-header">
  <h4 class="modal-title text-danger">{{error ? error.title : ''}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <small><code><pre>{{error ? error.errorText : ''}}</pre></code></small>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
</div>
