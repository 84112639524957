/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../node_modules/ng2-toasty/src/toasty.component.ngfactory";
import * as i4 from "ng2-toasty/src/toasty.component";
import * as i5 from "ng2-toasty/src/toasty.service";
import * as i6 from "./app.component";
import * as i7 from "./_services/app-wide-events.service";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "./_common/portal-session";
import * as i10 from "./_services/authentication.service";
import * as i11 from "./_common/utility.service";
import * as i12 from "./_services/toast-message.service";
import * as i13 from "./_services/router-ext.service";
import * as i14 from "./_services/portal-client-factory";
import * as i15 from "./_common/portal-storage";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0, null, View_AppComponent_2))], null, null); }
function View_AppComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "ng2-toasty", [], null, null, null, i3.View_ToastyComponent_0, i3.RenderType_ToastyComponent)), i0.ɵdid(5, 114688, null, 0, i4.ToastyComponent, [i5.ToastyConfig, i5.ToastyService], { position: [0, "position"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.toastyComponentPosition; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "body", [], [[8, "className", 0]], null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i6.AppComponent, [i7.AppWideEventService, i1.Router, i2.Location, i8.NgbModal, i9.PortalSession, i10.AuthenticationService, i11.UtilityService, i5.ToastyService, i12.ToastMessageService, i13.RouterExtService, i14.PortalClientFactory, i15.PortalLocalStorage], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cssClass; _ck(_v, 0, 0, currVal_0); }); }
var AppComponentNgFactory = i0.ɵccf("body", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
