import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Alert } from '../../../model/alert';
import { AuthenticationService } from '@_services/index';
import { CurrentTimeService } from '@_services/current-time.service';

declare var TfaUtil: any;

@Component({
  selector: 'app-tfa-setup',
  templateUrl: './tfa-setup.component.html',
  styleUrls: ['./tfa-setup.component.scss']
})
export class TfaSetupComponent implements OnInit {
  @Input() username: string;
  @Input() password: string;
  @Input() returnUrl: string;
  @Output('back') backEvent = new EventEmitter<boolean>();

  alert: Alert = null;
  loading: boolean = false;
  step: number = 0;
  twoFactorSecret: string;
  otpPath: string;
  isShowingSecret: boolean = false;
  oneTimePinCode: string = null;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private currentTimeService: CurrentTimeService
  ) { }

  ngOnInit() {
    this.twoFactorSecret = TfaUtil.generateBase32Secret(10);
    var provider = 'app.propertyme.com:';
    this.otpPath = `otpauth://totp/${provider}${this.username}'?secret=${this.twoFactorSecret}&issuer=PropertyMe`;
  }

  back() {
    this.alert = null;
    this.step--;
    if (this.step < 0) {
      this.backEvent.emit(true);
    }
    return false;
  }

  showSecret() {
    this.isShowingSecret = true;
  }

  secretForDisplay(): string {
    let tfaSecretToDisplay = '';
    for (var i = 0; i < this.twoFactorSecret.length; i = i + 4) {
      tfaSecretToDisplay += this.twoFactorSecret.substring(i, i + 4) + ' ';
    }
    return tfaSecretToDisplay;
  }

  async verify(): Promise<boolean> {

    let systemTime = await this.currentTimeService.getServerTime();
    let timeCorrection = systemTime - (new Date().getTime());
    if (!TfaUtil.verifyPinAgainstSecret(this.twoFactorSecret, this.oneTimePinCode, timeCorrection, { previousStep: 2, futureStep: 2 })) {
      this.alert = new Alert(`Invalid code. Please check if the time on your phone is ${new Date(new Date().getTime() + timeCorrection)}.`, 'danger');
      return false;
    }
    return true;
  }

  async setupTfa() {
    const that = this;
    try {
      let meta = {
        TwoFactorSecret: that.twoFactorSecret
      };
      await that.authenticationService.login(that.username, that.password, that.returnUrl, meta);
    } catch (error) {
      if (error.responseStatus && error.responseStatus.errorCode && error.responseStatus.message) {
        that.alert = new Alert(error.responseStatus.message, 'danger');
        return;
      }
      // let default error handler handle unknown errors.
      throw error;
    }
  }

  async next() {
    this.alert = null;
    if (this.step === 2) {
      this.loading = true;
      if (await this.verify()) {
        await this.setupTfa();
      } else {
        this.loading = false;
      }
    } else {
      this.step++;
    }
  }
}
