import {AuthenticateResponse} from '@app/app.dtos';
import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '@_services/index';
import {AppComponent} from '../app.component';
import {Alert} from '../model/alert';
import {SignUpEmailComponent} from '../sign-up/email/email.component';
import {BowserService} from 'ngx-bowser';
import {InitSignInRequest} from '@app/app.dtos';
import {SignInService} from "@app/sign-in/services/sign-in.service";
import {environment} from "../../environments/environment";
import {_finally} from "rxjs-compat/operator/finally";

;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  model: any = {};
  loading: boolean = true;
  submitting: boolean = false;
  requireTwoFactor: boolean = false;
  requireTwoFactorSetup: boolean = false;
  returnUrl: string;
  alert: Alert = null;
  noLogo: boolean = false;
  imageId: string;
  browserWarningMessage: string = null;
  isMemberPortalSignIn: boolean = false;
  customName: string;
  customLogoUrl: string;
  isAllowedToSignIn: boolean = true;
  managerAppUrl: string = environment.managerAppUrl;
  memberPortalAppUrl: string = environment.memberPortalUrl;
  errorCode: string;
  isAgentLoginOnMemberPortal: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private rootComp: AppComponent,
    private bowserService: BowserService,
    private signInService : SignInService
  ) {  }

  async ngOnInit() {
    // reset login status
    // this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.route.snapshot.queryParams['ReturnUrl'] || '/';
    var randomId = Math.floor(Math.random() * 5) + 1;
    this.imageId = 'image-container' + randomId;

    this.browserWarningMessage = this.checkBrowser();

    await this.load(this.returnUrl);
  }

  async load(returnUrl: string) {
    const that = this;
    try {
      let response = await this.signInService.initSignIn(returnUrl);
      this.isMemberPortalSignIn = response.isMemberPortalLogin;

      this.isAgentLoginOnMemberPortal = this.isMemberPortalSignIn && response.errorCode === 'AgentLoginOnMemberPortal';

      this.customName = response.portfolioName;
      this.customLogoUrl = response.customLogoUrl;

    } catch (error) {
      if (error.responseStatus) {
        if (error.responseStatus.errorCode === 'ArgumentException') {
          const msg = error.responseStatus.message === 'Invalid UserName or Password'
            ? 'Email or password is invalid'
            : error.responseStatus.message;
          that.alert = new Alert(msg, 'danger');
          that.isAllowedToSignIn = false;
          return;
        } else {
          throw error;
        }
      }

      // let default error handler handle unknown errors.
      throw error;
    } finally {
      this.loading = false;
    }
  }

  checkBrowser(): string {
    if (!this.supportBrowser() && !this.bowserService.bowser.ipad) {
      return this.showFailMessage();
    }

    return null;
  }

  supportBrowser(): boolean {
    return this.supportCSS() && this.supportPromise();
  }

  supportPromise(): boolean {
    return typeof Promise !== "undefined" &&
      Promise.toString().indexOf("[native code]") !== -1;
  };

  supportCSS(): boolean {
    var div = document.createElement('div');
    var vendors = 'Khtml Ms O Moz Webkit'.split(' ');
    var prop = 'webkitAlignContent';

    if (prop in div.style) return true;

    var len = vendors.length;

    prop = prop.replace(/^[a-z]/, function (val) {
      return val.toUpperCase();
    });

    while (len--) {
      if (vendors[len] + prop in div.style) {
        return true;
      }
    }
    return false;
  }

  showFailMessage(): string {
    var name_ver = this.bowserService.bowser.name + " " + this.bowserService.bowser.version;
    var msg = "<strong><i class='icon-warning-sign warning'></i> Your browser is outdated. </strong>" + (name_ver.length > 1 ? " (" + name_ver + ")" : "") + "";
    return msg;
  }


  async login() {
    const that = this;
    that.alert = null;
    that.submitting = true;
    const userName = that.model.username || '';
    try {
      await that.authenticationService.login(userName.trim(), that.model.password, that.returnUrl);
    } catch (error) {
      if (error.responseStatus) {
        switch (error.responseStatus.errorCode) {
          case 'RequireTwoFactorSecret':
            that.requireTwoFactorSetup = true;
            return;

          case 'RequireOneTimePinCode':
            that.requireTwoFactor = true;
            return;

          case 'RequireResetPassword':
            countDown(5);
            return;

          case 'ServerException':
            throw error;

          default:
            const msg = error.responseStatus.message === 'Invalid UserName or Password'
              ? 'Email or password is invalid'
              : error.responseStatus.message;
            that.alert = new Alert(msg, 'danger');
            return;
        }
      }

      // let default error handler handle unknown errors.
      throw error;
    }
    finally {
      that.submitting = false;
    }

    function countDown(i: number) {
      var int = setInterval(function () {
        that.alert = new Alert(`Your portfolio administrator has requested you to reset your account password. Redirect to Forgotten Password in ${i} ${(i > 1 ? 'seconds' : 'second')}.`, 'warning');
        i--;
        if (i < 0) {
          clearInterval(int);
          that.router.navigate(['/forgotten-password'], {preserveQueryParams: true});
        }
      }, 1000);
    }
  }

  onConnectYourProperty() {
    this.submitting = true;
    window.location.href = environment.memberPortalUrl + 'sign-up';
  }
}
