<div class="container pt-4">
  <div class="row pl-3 pb-3">
      <button class="btn btn-outline-primary mr-2" (click)="changePassword()">Change password</button>
      <button class="btn btn-outline-primary mr-2" (click)="changeEmail()" *ngIf="false">Change email</button>
      <button class="btn btn-outline-primary mr-2" (click)="edit()">Edit</button>
  </div>
  <div class="row">
    <div class="col-lg-12" test="member-profile">
      <div class="card card-profile mb-4" role="member-profile-contact" *ngIf="isLoaded && member">
        <div class="card-header" style="background-image: url(../assets/img/bubble-banner.png); background-size: cover"></div>
        <div class="text-center pt-2">
          <img class="card-profile-img" src="{{ avatarUrl | serverUrl }}">
          <h6 class="card-title">
            <span class="text-inherit" href="#">{{member.FirstName}} {{member.LastName}}</span>
          </h6>
          <div role="company-name">{{member.CompanyName}}</div>

          <div class="card-body text-left">
            <div class="row">
              <div class="col-12" role="mobile-phone">
                <span class="text-muted icon icon-mobile mr-1"></span>
                <b class="label text-muted d-none d-sm-inline">Mobile phone </b>
                {{member.MobilePhone}}
              </div>
            </div>

            <div class="row">
              <div class="col-12" role="work-phone">
                <span class="text-muted icon icon-phone mr-1"></span>
                <b class="label text-muted d-none d-sm-inline">Work phone </b>
                {{member.WorkPhone}}
              </div>
            </div>

            <div class="row">
              <div class="col-12" role="email">
                <span class="text-muted icon icon-email mr-1"></span>
                <b class="label text-muted d-none d-sm-inline">Email </b>
                {{member.RegisteredEmail}}
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12 ">
                <h6>Member since  {{member.RegisteredOn | date }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <app-nav-card class="d-md-none mt-4"></app-nav-card> -->

      <ngb-alert *ngIf="alert" [type]="alert.type" (close)="alert = null">{{ alert.message }}</ngb-alert>
    </div>
  </div>
</div>
