import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Alert } from '../../../model/alert';
import { AuthenticationService } from '@_services/index';

@Component({
  selector: 'app-pin-verification',
  templateUrl: './pin-verification.component.html',
  styleUrls: ['./pin-verification.component.scss']
})
export class PinVerificationComponent implements OnInit {
  @Input() username: string;
  @Input() password: string;
  @Input() returnUrl: string;
  @Output() back = new EventEmitter<boolean>();

  oneTimePinCode: string = null;
  alert: Alert = null;
  loading: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) { }

  ngOnInit() {
  }

  backToSignIn() {
    this.back.emit(true);
    return false;
  }

  async verify() {
    this.loading = true;
    const that = this;
    that.alert = null;
    try {
      let meta = {
        OneTimePinCode: that.oneTimePinCode
      };
      await that.authenticationService.login(that.username.trim(), that.password, that.returnUrl, meta);
      that.loading = false;
    } catch (error) {
      that.loading = false;
      if (error.responseStatus && error.responseStatus.errorCode && error.responseStatus.message) {
        that.alert = new Alert(error.responseStatus.message, 'danger');
        return;
      }
      // let default error handler handle unknown errors.
      throw error;
    }
  }
}
