var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RequestBase } from '../../_common/request-base';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { PortalClientFactory } from '@_services/portal-client-factory';
var UserService = /** @class */ (function () {
    function UserService(clientFactory) {
        this.clientFactory = clientFactory;
    }
    UserService.prototype.getCurrentUser = function () {
        var emptyUser = new Promise(function (resolve, reject) {
            resolve(null);
        });
        var client = this.clientFactory.createClient();
        var request = new GetCurrentUserRequest();
        if (client) {
            return client.get(request);
        }
        else {
            return emptyUser;
        }
    };
    UserService.prototype.updateMemberDetail = function (request) {
        var client = this.clientFactory.createClient();
        return client.post(request);
    };
    UserService.prototype.changePassword = function (request) {
        var client = this.clientFactory.createClient();
        return client.post(request);
    };
    UserService.prototype.changeEmail = function (request) {
        var client = this.clientFactory.createClient();
        return client.post(request);
    };
    UserService.prototype.activateEmail = function (request) {
        var client = this.clientFactory.createClient();
        return client.post(request);
    };
    return UserService;
}());
export { UserService };
// @Route("/user/current-user")
var GetCurrentUserRequest = /** @class */ (function (_super) {
    __extends(GetCurrentUserRequest, _super);
    function GetCurrentUserRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GetCurrentUserRequest.prototype.getTypeName = function () { return 'GetCurrentUserRequest'; };
    return GetCurrentUserRequest;
}(RequestBase));
export { GetCurrentUserRequest };
var GetCurrentUserResponse = /** @class */ (function () {
    function GetCurrentUserResponse() {
    }
    return GetCurrentUserResponse;
}());
export { GetCurrentUserResponse };
// @Route("/user/update-detail")
var UpdateMemberDetail = /** @class */ (function (_super) {
    __extends(UpdateMemberDetail, _super);
    function UpdateMemberDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UpdateMemberDetail.prototype.getTypeName = function () { return 'UpdateMemberDetail'; };
    return UpdateMemberDetail;
}(RequestBase));
export { UpdateMemberDetail };
// @Route("/user/change-password")
var ChangePassword = /** @class */ (function (_super) {
    __extends(ChangePassword, _super);
    function ChangePassword() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChangePassword.prototype.getTypeName = function () { return 'ChangePassword'; };
    return ChangePassword;
}(RequestBase));
export { ChangePassword };
// @Route("/user/change-email")
var ChangeEmail = /** @class */ (function (_super) {
    __extends(ChangeEmail, _super);
    function ChangeEmail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChangeEmail.prototype.getTypeName = function () { return 'ChangeEmail'; };
    return ChangeEmail;
}(RequestBase));
export { ChangeEmail };
// @Route("/user/activate-email")
var ActivateEmail = /** @class */ (function (_super) {
    __extends(ActivateEmail, _super);
    function ActivateEmail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ActivateEmail.prototype.getTypeName = function () { return 'ActivateEmail'; };
    return ActivateEmail;
}(RequestBase));
export { ActivateEmail };
