var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Authenticate } from '@app/app.dtos';
;
import { UtilityService } from '../_common/utility.service';
import { PortalSession } from '../_common/portal-session';
import { Router } from '@angular/router';
import { PortalClientFactory } from '@_services/portal-client-factory';
import { AppWideEventService } from '@_services/app-wide-events.service';
import { environment } from '../../environments/environment';
import * as rg4js from 'raygun4js';
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(clientFactory, eventService, portalSession, utility, router) {
        this.clientFactory = clientFactory;
        this.eventService = eventService;
        this.portalSession = portalSession;
        this.utility = utility;
        this.router = router;
    }
    AuthenticationService.prototype.login = function (username, password, returnUrl, meta, refresh) {
        if (meta === void 0) { meta = null; }
        if (refresh === void 0) { refresh = false; }
        return __awaiter(this, void 0, void 0, function () {
            var request, client, that, authenResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // remove user from local storage to log user out
                        this.portalSession.removeSession();
                        request = new Authenticate();
                        request.provider = 'credentials'; // Username/Password provider
                        request.userName = username;
                        request.password = password;
                        request.continue = returnUrl;
                        request.meta = meta;
                        client = this.clientFactory.createClient(true);
                        that = this;
                        return [4 /*yield*/, client.post(request)];
                    case 1:
                        authenResponse = _a.sent();
                        if (!(authenResponse && authenResponse.referrerUrl)) return [3 /*break*/, 4];
                        rg4js('setUser', {
                            identifier: authenResponse.userName,
                            isAnonymous: false,
                            email: authenResponse.userName,
                            fullName: authenResponse.displayName
                        });
                        // Add the username to the cookie
                        this.setUserName(authenResponse.userName);
                        if (!authenResponse.referrerUrl) return [3 /*break*/, 4];
                        if (!(authenResponse.referrerUrl.startsWith('/') && !authenResponse.referrerUrl.startsWith('/connect'))) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.router.navigateByUrl(authenResponse.referrerUrl)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        window.location.href = authenResponse.referrerUrl;
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AuthenticationService.prototype.setUserName = function (userName) {
        var cookieName = 'usc-' + environment.uscHostName;
        var cookieValue = (userName).toLowerCase().trim();
        var domain = '';
        var host = location.hostname.toLowerCase();
        if (host !== 'localhost' && host !== '127.0.0.1') {
            domain = '.propertyme.com';
        }
        var encoded = encodeURIComponent(btoa(cookieValue));
        var cookieText = cookieName + '=' + encoded + ';domain=' + domain;
        document.cookie = cookieText;
    };
    ;
    AuthenticationService.prototype.logout = function (removeSession) {
        if (removeSession === void 0) { removeSession = true; }
        // remove user from local storage to log user out
        if (removeSession) {
            this.portalSession.removeSession();
        }
        var client = this.clientFactory.createClient(true);
        var request = new Authenticate();
        request.provider = 'logout';
        if (client) {
            return client.post(request);
        }
        else {
            return new Promise(function (resolve, reject) {
                resolve(null);
            }).then(function () {
            });
        }
    };
    // please use this.portalSession.defaultAgent
    AuthenticationService.prototype.loadSession = function () {
        return this.portalSession.session;
    };
    AuthenticationService.prototype.setServerUrlCookie = function () {
        document.cookie = 'serverUrl=' + environment.serverUrl;
    };
    return AuthenticationService;
}());
export { AuthenticationService };
