/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error-alert.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_ErrorAlertComponent = [i0.styles];
var RenderType_ErrorAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorAlertComponent, data: {} });
export { RenderType_ErrorAlertComponent as RenderType_ErrorAlertComponent };
export function View_ErrorAlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "code", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-outline-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.error ? _co.error.title : ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.error ? _co.error.errorText : ""); _ck(_v, 10, 0, currVal_1); }); }
export function View_ErrorAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-alert", [], null, null, null, View_ErrorAlertComponent_0, RenderType_ErrorAlertComponent)), i1.ɵdid(1, 114688, null, 0, i2.ErrorAlertComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorAlertComponentNgFactory = i1.ɵccf("app-error-alert", i2.ErrorAlertComponent, View_ErrorAlertComponent_Host_0, { error: "error" }, {}, []);
export { ErrorAlertComponentNgFactory as ErrorAlertComponentNgFactory };
