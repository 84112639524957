import { ConnectStatusTypes }       from '@_services/connect-status-types';
import { Injectable } from '@angular/core';
import { AppWideEventService }      from '@_services/app-wide-events.service';
import {IPortalStorage, PortalLocalStorage, PortalCookieStorage} from './portal-storage-index';
import * as moment from 'moment';
import { Moment } from 'moment';
import { environment } from '../../environments/environment';
@Injectable()
export class PortalSession {
    // it's singleton service, to solve the cycle dependcy

    public session: SessionInfo
    public currentLanguage : string;
    public hasMultipleProperties = false;
    public bingSessionId : string;
    public portalStorage :  IPortalStorage;
    constructor(
        private eventService : AppWideEventService
    ) {

        let localStorageSupported = true;

        try {
            localStorageSupported = window != null && 'localStorage' in window &&
                typeof window['localStorage'] !== 'undefined' &&
                window['localStorage'] !== null;

            if (localStorageSupported) {
                localStorage.setItem('support', 'true');
                localStorage.removeItem('support');
            }
        } catch (ex) {
            localStorageSupported = false;
        }

        this.portalStorage = localStorageSupported ? new PortalLocalStorage() : new PortalCookieStorage();

        this.loadSession();

        this.setCurrentLanguage();
    }

    loadSession() : SessionInfo {
        const sessString = this.portalStorage.get('currentSession');
        if (sessString) {
            this.session = JSON.parse(sessString);
        }

        return this.session;
    }

    setSession(displayName : string, username : string) {
        const sessionDuration = environment.sessionDuration ? environment.sessionDuration : 9;
        const session = new SessionInfo(displayName, username, moment().add(sessionDuration, 'minute'));
        this.session = session;
        this.bingSessionId = null;
        this.portalStorage.set('currentSession', JSON.stringify(this.session));
        this.eventService.emitNewSession(session);
    }

    // Reload the session from a page refresh or from signing from the sign in page
    setSessionExpire() {
        if (this.session) {
            const diffInMs = moment.duration(moment(this.session.ExpiresAt).diff(moment())).asMilliseconds();

            // Prompt to sign in again when we expect the session will expire
            setTimeout(() =>
                    // this.eventService.emitSignInEvent()
                    this.eventService.emitConnectStatus(ConnectStatusTypes.unauthorized, 'Your session has expired')
                , diffInMs);

            setTimeout( () =>
                    this.eventService.emitCheckAppVersion()
                , diffInMs - 30000); //30 seconds before session expired

        }
    }

    removeSession() {
        this.portalStorage.remove('currentSession');
        this.session = null;
    }

    setCurrentLanguage() {
        this.currentLanguage = 'en-au';
    }

    /*
    setBingMapSession(sessionId : string) {

        if (this.session) {
            this.session.BingSessionId = sessionId;
        }

        this.bingSessionId = sessionId;

    }
    */
}

export class SessionInfo {
    constructor (public DisplayName : string,
                 public UserName : string,
                 public ExpiresAt : Moment
    )  { }

}

