/* Options:
Date: 2019-09-11 14:57:10
Version: 5.50
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44348/api

//GlobalNamespace: 
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
ExcludeTypes: Cookie,IRequest,IResponse,HttpResult
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

// @Flags()
export enum CacheControl
{
    None = 0,
    Public = 1,
    Private = 2,
    MustRevalidate = 4,
    NoCache = 8,
    NoStore = 16,
    NoTransform = 32,
    ProxyRevalidate = 64,
}

export interface IContentTypeWriter
{
}

export class ErrorMessage
{
    public constructor(init?:Partial<ErrorMessage>) { (<any>Object).assign(this, init); }
    public displayMode: string;
    public uiLocales: string;
    public error: string;
    public errorDescription: string;
    public requestId: string;
    public redirectUri: string;
    public responseMode: string;
}

export class CustomerAccessDto
{
    public constructor(init?:Partial<CustomerAccessDto>) { (<any>Object).assign(this, init); }
    public clientId: string;
    public clientName: string;
}

export class ScopeViewModel
{
    public constructor(init?:Partial<ScopeViewModel>) { (<any>Object).assign(this, init); }
    public name: string;
    public displayName: string;
    public description: string;
    public emphasize: boolean;
    public required: boolean;
    public checked: boolean;
}

export class PortfolioInfo
{
    public constructor(init?:Partial<PortfolioInfo>) { (<any>Object).assign(this, init); }
    public customerId: string;
    public displayName: string;
    public checked: boolean;
}

// @DataContract
export class ResponseError
{
    public constructor(init?:Partial<ResponseError>) { (<any>Object).assign(this, init); }
    // @DataMember(Order=1, EmitDefaultValue=false)
    public errorCode: string;

    // @DataMember(Order=2, EmitDefaultValue=false)
    public fieldName: string;

    // @DataMember(Order=3, EmitDefaultValue=false)
    public message: string;

    // @DataMember(Order=4, EmitDefaultValue=false)
    public meta: { [index:string]: string; };
}

// @DataContract
export class ResponseStatus
{
    public constructor(init?:Partial<ResponseStatus>) { (<any>Object).assign(this, init); }
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index:string]: string; };
}

// @Flags()
export enum RequestAttributes
{
    None = 0,
    Localhost = 1,
    LocalSubnet = 2,
    External = 4,
    Secure = 8,
    InSecure = 16,
    AnySecurityMode = 24,
    HttpHead = 32,
    HttpGet = 64,
    HttpPost = 128,
    HttpPut = 256,
    HttpDelete = 512,
    HttpPatch = 1024,
    HttpOptions = 2048,
    HttpOther = 4096,
    AnyHttpMethod = 8160,
    OneWay = 8192,
    Reply = 16384,
    AnyCallStyle = 24576,
    Soap11 = 32768,
    Soap12 = 65536,
    Xml = 131072,
    Json = 262144,
    Jsv = 524288,
    ProtoBuf = 1048576,
    Csv = 2097152,
    Html = 4194304,
    Wire = 8388608,
    MsgPack = 16777216,
    FormatOther = 33554432,
    AnyFormat = 67076096,
    Http = 67108864,
    MessageQueue = 134217728,
    Tcp = 268435456,
    EndpointOther = 536870912,
    AnyEndpoint = 1006632960,
    InProcess = 1073741824,
    InternalNetworkAccess = 1073741827,
    AnyNetworkAccessType = 1073741831,
    Any = 2147483647,
}

export interface IRequestPreferences
{
    acceptsGzip: boolean;
    acceptsDeflate: boolean;
}

export interface IHttpFile
{
    name: string;
    fileName: string;
    contentLength: number;
    contentType: string;
}

export class GetCurrentUserResponse
{
    public constructor(init?:Partial<GetCurrentUserResponse>) { (<any>Object).assign(this, init); }
    public memberId: string;
    public firstName: string;
    public lastName: string;
    public companyName: string;
    public registeredEmail: string;
    public workPhone: string;
    public mobilePhone: string;
    public photoDocumentId: string;
    public registeredOn: string;
}

export class ResetPasswordResponse
{
    public constructor(init?:Partial<ResetPasswordResponse>) { (<any>Object).assign(this, init); }
    public returnUrl: string;
}

export class ErrorResponse
{
    public constructor(init?:Partial<ErrorResponse>) { (<any>Object).assign(this, init); }
    public error: ErrorMessage;
}

export class InitSignInResponse
{
    public constructor(init?:Partial<InitSignInResponse>) { (<any>Object).assign(this, init); }
    public isMemberPortalLogin: boolean;
    public customLogoUrl: string;
    public portfolioName: string;
    public errorCode: string;
}

export class GetConsentResponse
{
    public constructor(init?:Partial<GetConsentResponse>) { (<any>Object).assign(this, init); }
    public returnUrl: string;
    public clientName: string;
    public clientUrl: string;
    public clientLogoUrl: string;
    public identityScopes: ScopeViewModel[];
    public resourceScopes: ScopeViewModel[];
    public portfolioInfo: PortfolioInfo[];
}

export class ProcessConsentResponse
{
    public constructor(init?:Partial<ProcessConsentResponse>) { (<any>Object).assign(this, init); }
    public isRedirect: boolean;
    public redirectUri: string;
    public clientId: string;
}

export class AuthenticationFailureResponse
{
    public constructor(init?:Partial<AuthenticationFailureResponse>) { (<any>Object).assign(this, init); }
    public errorId: string;
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    public constructor(init?:Partial<AuthenticateResponse>) { (<any>Object).assign(this, init); }
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=9)
    public meta: { [index:string]: string; };
}

// @DataContract
export class AssignRolesResponse
{
    public constructor(init?:Partial<AssignRolesResponse>) { (<any>Object).assign(this, init); }
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;
}

// @DataContract
export class UnAssignRolesResponse
{
    public constructor(init?:Partial<UnAssignRolesResponse>) { (<any>Object).assign(this, init); }
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;
}

// @DataContract
export class RegisterResponse
{
    public constructor(init?:Partial<RegisterResponse>) { (<any>Object).assign(this, init); }
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public referrerUrl: string;

    // @DataMember(Order=5)
    public bearerToken: string;

    // @DataMember(Order=6)
    public refreshToken: string;

    // @DataMember(Order=7)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=8)
    public meta: { [index:string]: string; };
}

export class GetCurrentUserRequest implements IReturn<GetCurrentUserResponse>
{
    public constructor(init?:Partial<GetCurrentUserRequest>) { (<any>Object).assign(this, init); }
    public createResponse() { return new GetCurrentUserResponse(); }
    public getTypeName() { return 'GetCurrentUserRequest'; }
}

export class ForgottenPasswordRequest implements IReturnVoid
{
    public constructor(init?:Partial<ForgottenPasswordRequest>) { (<any>Object).assign(this, init); }
    public email: string;
    public returnUrl: string;
    public createResponse() {}
    public getTypeName() { return 'ForgottenPasswordRequest'; }
}

export class ValidatePasswordResetRequest implements IReturnVoid
{
    public constructor(init?:Partial<ValidatePasswordResetRequest>) { (<any>Object).assign(this, init); }
    public id: string;
    public createResponse() {}
    public getTypeName() { return 'ValidatePasswordResetRequest'; }
}

export class ResetPasswordRequest implements IReturn<ResetPasswordResponse>
{
    public constructor(init?:Partial<ResetPasswordRequest>) { (<any>Object).assign(this, init); }
    public id: string;
    public password: string;
    public confirmPassword: string;
    public createResponse() { return new ResetPasswordResponse(); }
    public getTypeName() { return 'ResetPasswordRequest'; }
}

// @Route("/logout")
export class LogoutRequest
{
    public constructor(init?:Partial<LogoutRequest>) { (<any>Object).assign(this, init); }
    public logoutId: string;
}

export class SendEmail implements IReturnVoid
{
    public constructor(init?:Partial<SendEmail>) { (<any>Object).assign(this, init); }
    public toName: string;
    public toAddress: string;
    public subject: string;
    public body: string;
    public isHtml: boolean;
    public fromName: string;
    public fromAddress: string;
    public replyToAddress: string;
    public inReplyTo: string;
    public createResponse() {}
    public getTypeName() { return 'SendEmail'; }
}

export class ErrorRequest implements IReturn<ErrorResponse>
{
    public constructor(init?:Partial<ErrorRequest>) { (<any>Object).assign(this, init); }
    public errorId: string;
    public createResponse() { return new ErrorResponse(); }
    public getTypeName() { return 'ErrorRequest'; }
}

// @Route("/customeraccess/{CustomerId}", "GET")
export class GetCustomerAccessRequest implements IReturn<CustomerAccessDto[]>
{
    public constructor(init?:Partial<GetCustomerAccessRequest>) { (<any>Object).assign(this, init); }
    public customerId: string;
    public createResponse() { return new Array<CustomerAccessDto>(); }
    public getTypeName() { return 'GetCustomerAccessRequest'; }
}

// @Route("/customeraccess/{CustomerId}/{ClientId}", "DELETE")
export class RevokeCustomerAccessRequest implements IReturnVoid
{
    public constructor(init?:Partial<RevokeCustomerAccessRequest>) { (<any>Object).assign(this, init); }
    public customerId: string;
    public clientId: string;
    public createResponse() {}
    public getTypeName() { return 'RevokeCustomerAccessRequest'; }
}

export class GetCurrentTimeRequest implements IReturn<number>
{
    public constructor(init?:Partial<GetCurrentTimeRequest>) { (<any>Object).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'GetCurrentTimeRequest'; }
}

export class InitSignInRequest implements IReturn<InitSignInResponse>
{
    public constructor(init?:Partial<InitSignInRequest>) { (<any>Object).assign(this, init); }
    public returnUrl: string;
    public createResponse() { return new InitSignInResponse(); }
    public getTypeName() { return 'InitSignInRequest'; }
}

// @Route("/consent")
export class GetConsentRequest implements IReturn<GetConsentResponse>
{
    public constructor(init?:Partial<GetConsentRequest>) { (<any>Object).assign(this, init); }
    public returnUrl: string;
    public createResponse() { return new GetConsentResponse(); }
    public getTypeName() { return 'GetConsentRequest'; }
}

export class ProcessConsentRequest implements IReturn<ProcessConsentResponse>
{
    public constructor(init?:Partial<ProcessConsentRequest>) { (<any>Object).assign(this, init); }
    public isAllowing: boolean;
    public returnUrl: string;
    public scopesConsented: string[];
    public customerId: string;
    public createResponse() { return new ProcessConsentResponse(); }
    public getTypeName() { return 'ProcessConsentRequest'; }
}

// @Route("/config")
export class GetConfigRequest implements IReturn<any>
{
    public constructor(init?:Partial<GetConfigRequest>) { (<any>Object).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'GetConfigRequest'; }
}

// @Route("/auth/authenticationfailure")
export class AuthenticationFailureRequest implements IReturn<AuthenticationFailureResponse>
{
    public constructor(init?:Partial<AuthenticationFailureRequest>) { (<any>Object).assign(this, init); }
    public error: string;
    public errorDescription: string;
    public redirectUri: string;
    public createResponse() { return new AuthenticationFailureResponse(); }
    public getTypeName() { return 'AuthenticationFailureRequest'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @Route("/authenticate")
// @Route("/authenticate/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    public constructor(init?:Partial<Authenticate>) { (<any>Object).assign(this, init); }
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe: boolean;

    // @DataMember(Order=8)
    public continue: string;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public meta: { [index:string]: string; };
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    public constructor(init?:Partial<AssignRoles>) { (<any>Object).assign(this, init); }
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    public constructor(init?:Partial<UnAssignRoles>) { (<any>Object).assign(this, init); }
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

// @Route("/register")
// @DataContract
export class Register implements IReturn<RegisterResponse>, IPost
{
    public constructor(init?:Partial<Register>) { (<any>Object).assign(this, init); }
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public firstName: string;

    // @DataMember(Order=3)
    public lastName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public email: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public confirmPassword: string;

    // @DataMember(Order=8)
    public autoLogin: boolean;

    // @DataMember(Order=9)
    public continue: string;

    // @DataMember(Order=10)
    public errorView: string;
    public createResponse() { return new RegisterResponse(); }
    public getTypeName() { return 'Register'; }
}

