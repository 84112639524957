<div class="container pt-4 mt-5">

  <h3><strong>Help</strong></h3>

  <div class="row">
    <div class="col-lg-6">

      <div class="card-body">

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet augue non orci blandit eleifend. Nam sit amet congue ligula. Etiam velit tortor, vehicula id vestibulum vel, ornare in nulla. Vestibulum porttitor, dui ut gravida posuere, velit nulla aliquet odio, id aliquam metus lorem eget sem. Sed quis orci non dui pharetra fringilla. Phasellus eu turpis tristique, bibendum elit sit amet, cursus ante. Nunc convallis, elit sit amet sollicitudin suscipit, justo lacus interdum diam, nec pellentesque libero nibh nec neque. Suspendisse potenti. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Sed semper neque non pulvinar lobortis. Cras posuere ac justo a auctor.
        </p>
        <p>
          Suspendisse bibendum justo accumsan nulla pulvinar, vitae lobortis lacus viverra. Ut tincidunt risus et rhoncus sagittis. Duis semper lectus tincidunt est lacinia, eget malesuada lectus viverra. Praesent tempus ipsum in ex interdum, quis laoreet massa mattis. Donec erat quam, pulvinar ac tincidunt eu, euismod eget sapien. Morbi eget ligula lectus. Nam varius ex et nunc laoreet, eget varius felis pharetra. Duis a purus vehicula, interdum libero non, imperdiet ante. Suspendisse pharetra nulla sapien, vitae pharetra elit viverra in. Vestibulum non neque interdum, aliquet lorem consequat, tincidunt est. Nulla eget tincidunt orci, semper auctor sem.
        </p>
        <p>
          Maecenas mauris urna, ornare in nisi in, tincidunt egestas orci. Cras massa nulla, lacinia ac euismod at, porta quis lorem. Duis finibus placerat auctor. Nullam blandit ut mauris a euismod. Integer id molestie elit. Proin semper ligula vel orci aliquam fermentum vitae eu diam. Nulla facilisi. Ut velit quam, blandit eu blandit sed, sollicitudin sit amet leo. Fusce a finibus velit. Nam id auctor urna, ut faucibus diam.
        </p>
        <p>© 2017 PropertyMe, Inc. <a target="_blank" href="http://www.propertyme.com.au">www.propertyme.com.au</a></p>

      </div>
    </div>

    <div class="col-lg-6">
      <img width="500" src="/assets/img/Logo-trans-1200px.png" class="img-responsive" alt="" sizes="(max-width: 500px) 100vw, 500px">
    </div>


  </div>
</div>
