<div class="container pt-4 mt-5">
  <h3><strong>PropertyMe Terms and Conditions&nbsp;</strong></h3>
  <div class="row">
    <div class="col-xs-12 ml-5">
      <h5></h5>
      <p>&nbsp;</p>
      <h5><strong>Welcome to PropertyMe, an online property service designed especially for property management businesses and consumers.</strong></h5>
      <p>These terms and conditions apply to the use of the PropertyMe web site and associated applications, including the use of the services offered on the PropertyMe web site and associated applications.&nbsp; By requesting to use the Services and by using the PropertyMe web site and associated applications, you agree to be bound by these terms and conditions.&nbsp; You acknowledge that you have read and understood these terms and conditions and you also acknowledge that you have the authority to act on behalf of any person for whom you are using the Services.&nbsp; If you do not accept these terms and conditions, you must refrain from using the web site and associated applications.&nbsp; These terms and conditions must be read in conjunction with any other applicable terms and conditions governing the use of the PropertyMe web site and associated applications.</p>
      <h5></h5>
      <p>&nbsp;</p>
      <h5><strong>Terminology</strong></h5>
      <p>In these terms and conditions, the expressions “we”, “us”&nbsp;and “our”&nbsp;are a reference to Property Me Pty Ltd.</p>
      <p style="padding-left: 30px;"><strong>“Access Fee”</strong>&nbsp;means the monthly fee (excluding any taxes and duties) payable by You in accordance with the fee schedule set out on PropertyMe web site (which we may change from time to time on notice to You.</p>
      <p style="padding-left: 30px;"><strong>“Data”</strong>&nbsp;means any data inputted by You or with Your authority into the web site or associated applications.</p>
      <p style="padding-left: 30px;">“Invited User”&nbsp;means any person or entity, other than the Subscriber, that uses the Services with the authorisation of the Subscriber from time to time.</p>
      <p style="padding-left: 30px;"><strong>“Services”</strong>&nbsp;means the online property services made available via the PropertyMe web site and associated applications.</p>
      <p style="padding-left: 30px;"><strong>“Subscriber”</strong>&nbsp;means the person who registers to use the Services, and where the context permits, includes any entity on whose behalf that person registers to use the Services.</p>
      <p style="padding-left: 30px;"><strong>“You”</strong>&nbsp;means the Subscriber, and where the context permits, an Invited User.</p>
      <p>&nbsp;</p>
      <h5><strong>Amendments to terms and conditions</strong></h5>
      <p>We reserve the right to amend these terms and conditions from time to time.&nbsp; Amendments will be effective immediately upon notification on the PropertyMe web site.&nbsp; Your continued use of the Services and the PropertyMe web site following such notification will represent an agreement by you to be bound by the terms and conditions as amended.</p>
      <p>&nbsp;</p>
      <h5><strong>Registration</strong></h5>
      <p>In order to be able to access the Services, you must complete your registration details in the manner described on the PropertyMe web site.</p>
      <p>You agree to ensure that your registration details are true and accurate at all times.&nbsp; Specifically, you must notify us of any change to the registration details as originally supplied.</p>
      <p>Upon registration, we will send to you an email which contains a link which will take you to a page where you will be able to enter your password and to activate your account. &nbsp; You agree to pay for the Services in the manner specified in these terms and conditions.</p>
      <p>&nbsp;</p>
      <h5><strong>Access to Services</strong></h5>
      <p>We grant You the non-exclusive, non-transferrable right to access and use the Services via the PropertyMe web site and associated applications, according to Your subscription type.</p>
      <p>You acknowledge and agree that:</p>
      <p style="padding-left: 30px;">&nbsp;– you determine who is an Invited User and the level of access to the Services of that Invited User;</p>
      <p style="padding-left: 30px;">&nbsp;– you are responsible for use of the Services by the Invited User;</p>
      <p style="padding-left: 30px;">&nbsp;– you are responsible to resolve any dispute between You and an Invited User regarding access to the Services.</p>
      <p>&nbsp;</p>
      <h5><strong>Trial&nbsp;</strong></h5>
      <p>When You first sign up for access to the Services You are permitted a no-obligation trial of the Services under the defined trial usage conditions. If You then choose to continue using the Services You will be invoiced from the day You first added Your billing details into the Services. If You choose not to continue using the Services, You may delete Your account in the ‘Subscription’ section of the Services.</p>
      <p>&nbsp;</p>
      <h5><strong>Services</strong></h5>
      <p>All Services are provided in good faith.&nbsp; We derive our information from sources which we believe to be accurate and up to date as at the date of publication.&nbsp; We nevertheless reserve the right to update this information at any time.</p>
      <p>We are not liable for any loss resulting from any action taken or reliance made by you on any information we provide.</p>
      <p>In the case of technical problems You must make all reasonable efforts to investigate and diagnose such problems before contacting us.&nbsp; If You still need technical help, please check, the support provided online by us on the web site or failing that email us at support@propertyme.com.</p>
      <p>You agree to use our Services for lawful purposes only.</p>
      <h5></h5>
      <h5><strong>Payment</strong></h5>
      <p>Invoices will be issued monthly, commencing one month from the date You first added Your billing details into the Services. All invoices will include the Access Fee for use in the preceding month.&nbsp; All invoices will be sent to You, or to Your billing contact whose details are provided by You, by email.&nbsp; All amounts specified in any invoice must be paid within 10 days of the invoice date. You are responsible for payment of all taxes and duties in addition to the Access Fee.</p>
      <p>We may from time to time offer You special pricing or discounts for the Access Fees due to the number of properties that You have added to the Services or that have been added with Your authority or as a result of Your use of the Services (‘Subscriptions’). Eligibility for such special pricing or discounts is conditional upon Your acceptance of responsibility for payment of any Access Fees in relation to all of Your Subscriptions.</p>
      <p>Without prejudice to any other rights that we may have under these terms and conditions or at law, we reserve the right to render invoices for the full (non-discounted) Access Fees due or to suspend or terminate Your use of the Services in respect of any or all of Your Subscriptions in the event that any invoices for those Access Fees are not paid in full by the due date for payment.</p>
      <p>Where available, automated bank account transaction data feeds will be provided to You free of charge. However, we reserve the right to pass on any charges related to the provision of bank feed data on a case-by-case basis at our sole discretion.&nbsp; We would first inform You via email to indicate what those charges are likely to be (as such charges may vary depending on the bank and the volume of bank feeds). If, at that time, you decide to discontinue, you must give us adequate notice of the automated bank account transaction data feeds in question. Upon receiving such notice we will arrange for such feeds to be terminated in accordance with the relevant bank’s usual practices.</p>
      <p>&nbsp;</p>
      <h5><strong>Acknowledgements</strong></h5>
      <p>You acknowledge that where You have registered to use the Services on behalf of another person, You are responsible for the acts and omissions of that other person.</p>
      <p>&nbsp;</p>
      <h5><strong>Warranty</strong></h5>
      <p>You warrant to us that where personal information forms part of the Data you have obtained the consent of the owner of that personal information to the use and disclosure by Us of that personal information, in any manner that We see fit.</p>
      <p>&nbsp;</p>
      <h5><strong>Disclaimer</strong></h5>
      <p>We do not accept responsibility for any loss or damage, however caused (including through negligence), which you may directly or indirectly suffer in connection with your use of the Services or the PropertyMe web site and associated applications or any linked web site, nor do we accept any responsibility for any such loss arising out of your use of or reliance on information contained on or accessed through the PropertyMe web site and associated applications.</p>
      <p>To the extent permitted by law, any condition or warranty which would otherwise be implied into these terms and conditions is hereby excluded.&nbsp; Where legislation implies any condition or warranty, and that legislation prohibits us from excluding or modifying the application of, or our liability under, any such condition or warranty, that condition or warranty will be deemed included but our liability will be limited for a breach of that condition or warranty to one or more of the following:</p>
      <p style="padding-left: 30px;"><strong>(a)</strong>&nbsp;if the breach relates to goods,</p>
      <p style="padding-left: 30px;">(i)&nbsp;the replacement of the goods or the supply of equivalent goods;</p>
      <p style="padding-left: 30px;">(ii) the repair of such goods,</p>
      <p style="padding-left: 30px;">(iii) the payment of the cost of replacing the goods or of acquiring equivalent goods or</p>
      <p style="padding-left: 30px;">(iv) the payment of the cost of having the goods repaired; and</p>
      <p>&nbsp;</p>
      <p style="padding-left: 30px;"><strong>(b)</strong>&nbsp;if the breach related to services,</p>
      <p style="padding-left: 30px;">(i) the supplying of the services again, or</p>
      <p style="padding-left: 30px;">(ii) the payment of the cost of having the services supplied again.</p>
      <p>&nbsp;</p>
      <h5><strong>Exception to disclaimer</strong></h5>
      <p>This disclaimer set out in these terms and conditions does not attempt or purport to exclude liability arising under statute if, and to the extent, such liability cannot be lawfully excluded.</p>
      <p>If You suffer loss or damage as a result of our negligence or failure to comply with these terms and conditions and we are unable at law to exclude liability then any claim by You against us arising from our negligence or failure will be limited in respect of any one incident, or series of connected incidents, to the Access Fees paid by You in the previous 12 months.</p>
      <p>&nbsp;</p>
      <h5><strong>Specific warnings</strong></h5>
      <p>You must ensure that your access to the PropertyMe web site and associated applications is not illegal or prohibited by laws which apply to you.</p>
      <p>You must take your own precautions to ensure that the process which you employ for accessing the PropertyMe web site and associated applications does not expose you to the risk of viruses, malicious computer code or other forms of interference which may damage your own computer system.&nbsp; For the removal of doubt, we do not accept responsibility for any interference or damage to your own computer system which arises in connection with your use of the PropertyMe web site and associated applications or any linked web site.</p>
      <p>Whilst we have no reason to believe that any information contained on the PropertyMe web site and associated applications is inaccurate, we do not warrant the accuracy, adequacy or completeness of such information, nor do we undertake to keep the PropertyMe web site and associated applications updated.&nbsp; We do not accept responsibility for loss suffered as a result of reliance by you upon the accuracy or currency of information contained on the PropertyMe web site and associated applications.</p>
      <p>In addition, we do not make any representations or warranties that the information we provide is reliable, accurate up to date or complete or that your access to that information will be uninterrupted, timely or secure.</p>
      <p>We do not give you any assurances that any information contained on the PropertyMe web site and associated applications will meet your requirements or be suitable for your purposes or that it will be uninterrupted or error-free.&nbsp; Access may be affected by our network or equipment capacity, connection availability, scheduled or unscheduled downtime for maintenance purposes or interruptions which generally affect the Internet.&nbsp; If interruptions or errors occur due to circumstances within Our control, we will try to resolve them in a timely way in order to restore Your access.</p>
      <p>You agree that you will not rely on any such information or its availability and that any reliance you make will be on your own independent assessments with the aid of qualified independent advice.</p>
      <p>Responsibility for the content of advertisements appearing on the PropertyMe web site and associated applications (including hyperlinks to advertisers’&nbsp;own web sites) rests solely with the advertisers.&nbsp; The placement of such advertisements does not constitute a recommendation or endorsement by us of the advertisers’&nbsp;products and each advertiser is solely responsible for any representations made in connection with its advertisement.</p>
      <p>&nbsp;</p>
      <h5><strong>Copyright</strong></h5>
      <p>Copyright in the PropertyMe web site and associated applications (including text, graphics, logos, icons, sound recordings and software) is owned or licensed by us.&nbsp; Information procured from a third party may be the subject of copyright owned by that third party.&nbsp; Other than for the purposes of, and subject to the conditions prescribed under, the&nbsp;<em>Copyright Act</em>&nbsp;<em>1968</em>&nbsp;(Cth) and similar legislation which applies in your location, and except as expressly authorised by these terms and conditions, you may not in any form or by any means:</p>
      <p style="padding-left: 30px;">– adapt, reproduce, store, distribute, print, display, perform, publish or create derivative works from any part of the PropertyMe web site and associated applications; or</p>
      <p style="padding-left: 30px;">– commercialise any information products or services obtained from any part of the PropertyMe web site and associated applications;</p>
      <p style="padding-left: 30px;">– without our written permission or, in the case of third party material, from the owner of the copyright in that material.</p>
      <p>&nbsp;</p>
      <h5><strong>Trade marks</strong></h5>
      <p>Except where otherwise specified, any word or device to which is attached the ™&nbsp;or ®&nbsp;symbol is a trade mark.</p>
      <p>If you use any of our trade marks in reference to our activities, products or services, you must include a statement attributing that trade mark to us.&nbsp; You must not use any of our trade marks:</p>
      <p style="padding-left: 30px;">– in or as the whole or part of your own trade marks;</p>
      <p style="padding-left: 30px;">– in connection with activities, products or services which are not ours;</p>
      <p style="padding-left: 30px;">– in a manner which may be confusing, misleading or deceptive;</p>
      <p style="padding-left: 30px;">– in a manner that disparages us or our information, products or services (including the PropertyMe web site and associated applications).</p>
      <p>&nbsp;</p>
      <h5><strong>Restricted use</strong></h5>
      <p>Unless we agree otherwise in writing, you may only use the Service and access the PropertyMe web site and associated applications for your lawful internal business use.&nbsp; Without limiting the foregoing, you may not without our written permission on-sell information obtained from the PropertyMe web site and associated applications.</p>
      <p>&nbsp;</p>
      <h5><strong>Linked web sites</strong></h5>
      <p>The PropertyMe web site and associated applications may contain links to other web sites (“linked web sites”).&nbsp; Those links are provided for convenience only and may not remain current or be maintained.</p>
      <p>We are not responsible for the content or privacy practices associated with linked web sites.</p>
      <p>Our links with linked web sites should not be construed as an endorsement, approval or recommendation by us of the owners or operators of those linked web sites, or of any information, graphics, materials, products or services referred to or contained on those linked web sites, unless and to the extent stipulated to the contrary.</p>
      <p>&nbsp;</p>
      <h5><strong>Privacy Policy</strong></h5>
      <p>We undertake to comply with the terms of our privacy policy which is annexed to these Terms and Conditions.</p>
      <p>&nbsp;</p>
      <h5><strong>Security of information</strong></h5>
      <p>Unfortunately, no data transmission over the Internet can be guaranteed as totally secure.&nbsp; Whilst we strive to protect such information, we do not warrant and cannot ensure the security of any information which you transmit to us.&nbsp; Accordingly, any information which you transmit to us is transmitted at your own risk.&nbsp; Nevertheless, once we receive your transmission, we will take reasonable steps to preserve the security of such information.</p>
      <p>You undertake to keep all usernames and passwords required to access the Services secure and confidential. You must immediately notify us of any unauthorised use of Your passwords or any other breach of security and we will reset Your password.&nbsp; When requested, You must take all other actions that we deem reasonably necessary to maintain or increase the security of our computing systems and networks and Your access to the Services.</p>
      <p>When accessing and using the Services, You must:</p>
      <p style="padding-left: 30px;">– not attempt to undermine the security or integrity of our&nbsp; computing systems or networks or, computing systems and networks of any third party host;</p>
      <p style="padding-left: 30px;">– not use, or misuse, the Services in any way which may impair the functionality of the Services or web site, or other systems used to deliver the Services or impair the ability of any other user to use the Services or web site;</p>
      <p style="padding-left: 30px;">– not attempt to gain unauthorised access to any materials other than those to which You have been given express permission to access or to the computer system on which the Services are hosted; and</p>
      <p style="padding-left: 30px;">– not transmit, or input into the web site, any files that may damage any other person’s computing devices or software, content that may be offensive, or material or Data in violation of any law.</p>
      <p>Use of the Service may be subject to limitations including, but not limited, to monthly transaction volumes and the number of calls You are permitted to make against our application programming interface.&nbsp; Any such limitations will be advised.</p>
      <p>&nbsp;</p>
      <h5><strong>Termination of access</strong></h5>
      <p>You are entitled to cancel your subscription at any time.</p>
      <p>If you breach any of these terms and conditions (including for failure to pay any Access Fee), we may immediately issue a warning, temporarily suspend or permanently prevent your access to all or parts of the PropertyMe web site and associated applications or the Services.</p>
      <p>In any event, access to the PropertyMe web site and associated applications may be terminated at any time by us and for any reason without prior notice to you.</p>
      <p>On termination for any reason whatsoever, you must immediately cease use of the Services and the PropertyMe web site and associated applications.&nbsp; You remain liable for any unpaid fees.</p>
      <p>PropertyMe will not provide any refund or other compensation for any remaining prepaid period for a prepaid Access Fee subscription.</p>
      <p>Our disclaimer will nevertheless survive any such termination.</p>
      <p>&nbsp;</p>
      <h5><strong>Governing law</strong></h5>
      <p>These terms and conditions are governed by the laws in force in New South Wales.&nbsp; You agree to submit to the exclusive jurisdiction of the courts of that jurisdiction.</p>
      <p>&nbsp;</p>
      <h5><strong>General</strong></h5>
      <p>We accept no liability for any failure to comply with these terms and conditions where such failure is due to circumstances beyond our reasonable control.</p>
      <p>If we waive any rights available to us under these terms and conditions on one occasion, this does not mean that those rights will automatically be waived on any other occasion.</p>
      <p>If any of these terms and conditions are held to be invalid, unenforceable or illegal for any reason, the remaining terms and conditions shall nevertheless continue in full force.</p>
      <p>&nbsp;</p>
      <h5><strong>Privacy policy</strong></h5>
      <p>We may collect web site visitors’&nbsp;contact information (like their email addresses).&nbsp; Unless you object, this information may be used:</p>
      <p style="padding-left: 30px;">– to send news, information about our activities and general promotional material which we believe may be useful to you;</p>
      <p style="padding-left: 30px;">– to monitor who is accessing the web site or using services offered on the web site; and</p>
      <p style="padding-left: 30px;">-to profile the type of people accessing the web site.</p>
      <p>If you do not wish to have your personal information used in this manner or for any other specific purpose, you can email us accordingly.</p>
      <p>We utilise “cookies”&nbsp;which enable us to monitor traffic patterns and to serve you more efficiently if you revisit the site.&nbsp; A cookie does not identify you personally but it does identify your computer.&nbsp; You can set your browser to notify you when you receive a cookie and this will provide you with an opportunity to either accept or reject it in each instance.</p>
      <p>We will not sell or otherwise provide your personal information to a third party, or make any other use of your personal information, for any purpose which is not incidental to your use of the PropertyMe web site and associated applications.&nbsp; For the removal of doubt, personal information will not be used for any purpose which a reasonable person in your position would not expect.</p>
      <p>If you request us not to use personal information in a particular manner or at all, we will adopt all reasonable measures to observe your request but we may still use or disclose that information if:</p>
      <p style="padding-left: 30px;">– we subsequently notify you of the intended use or disclosure and you do not object to that use or disclosure;</p>
      <p style="padding-left: 30px;">– we believe that the use or disclosure is reasonably necessary to assist a law enforcement agency or an agency responsible for government or public security in the performance of their functions; or</p>
      <p style="padding-left: 30px;">– we are required by law to disclose the information.</p>
      <p style="padding-left: 30px;">We will preserve the content of any email you send us if we believe we have the legal requirement to do so.</p>
      <p>Your email message content may be monitored by us for trouble-shooting or maintenance purposes or if any form of email abuse is suspected.</p>
      <p>Personal information which we collect may be aggregated for analysis but in such circumstances we would ensure that individuals would remain anonymous.</p>
      <p>All personal information which we collect (including your contact details and, if relevant, credit card details) is kept confidential to the best of our ability.&nbsp; You will appreciate, however, that we cannot guarantee the security of transmission.</p>
      <p>You are entitled to have access to any personal information relating to you which you have previously supplied to us over the PropertyMe web site and associated applications.&nbsp; You are entitled to edit or delete such information unless we are required by law to retain it.</p>
      <p>If you wish to comment on or query our privacy policy, or if you wish to make an inquiry regarding any personal information relating to you which may be in our possession, contact us on privacy@propertyme.com.</p>
    </div>
  </div>
</div>
