import * as rg4js from 'raygun4js';
import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { AppWideEventService } from './app-wide-events.service';
import { environment } from '../../environments/environment';

rg4js('apiKey', environment.raygunKey);
rg4js('setVersion', environment.version);
rg4js('enableCrashReporting', true);
rg4js('enablePulse', false);


@Injectable()
export class PortalErrorHandlerService implements ErrorHandler  {

  constructor(
    private eventService: AppWideEventService,
    private ngZone: NgZone) {}

  handleError(error) {
    const that = this;
    if (error && error.promise) { // Global promise catch handler.
      error.promise.catch(err => {
        this.ngZone.run(() => {
          if (err && err.responseStatus) {
            that.eventService.emitError(err.responseStatus);
          } else {
            that.eventService.emitError(err);
            rg4js('send', {
              error: err
            });
          }
        });
      });
    } else {
      this.ngZone.run(() => {
        that.eventService.emitError(error);
        rg4js('send', {
          error: error
        });
      });
    }
  }

}
