var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Alert } from '../model/alert';
import { ConsentService, ScopeGroupViewModel } from './services/consent.service';
import { Compiler } from '@angular/core';
var ConsentComponent = /** @class */ (function () {
    function ConsentComponent(consentService, route, router, compiler) {
        this.consentService = consentService;
        this.route = route;
        this.router = router;
        this.compiler = compiler;
        this.loading = true;
        this.compiler.clearCache();
    }
    ConsentComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.route.snapshot.queryParams['ReturnUrl'];
                        return [4 /*yield*/, this.load()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ConsentComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var r, selectedPortfolio;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.consentService.getConsent(this.returnUrl)];
                    case 1:
                        r = _a.sent();
                        this.consent = r;
                        if (r && r.portfolioInfo) {
                            selectedPortfolio = r.portfolioInfo.find(function (item) { return item.checked; });
                            if (selectedPortfolio) {
                                this.customerId = selectedPortfolio.customerId;
                            }
                        }
                        if (this.consent && this.consent.resourceScopes && this.consent.resourceScopes.length > 0) {
                            this.scopeGroups = this.buildScopeGroups(this.consent.resourceScopes);
                        }
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ConsentComponent.prototype.process = function (isAllowing) {
        return __awaiter(this, void 0, void 0, function () {
            var that, scopesConsented, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        that = this;
                        scopesConsented = that.consent
                            .identityScopes
                            .filter(function (item) { return item.checked; })
                            .map(function (item) { return item.name; })
                            .concat(that.consent.resourceScopes.filter(function (item) { return item.checked; }).map(function (item) { return item.name; }));
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, that.consentService.processConsent(isAllowing, scopesConsented, that.returnUrl, that.customerId)];
                    case 2:
                        response = _a.sent();
                        if (!(response.redirectUri.startsWith('/') && !response.redirectUri.startsWith('/connect'))) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.router.navigateByUrl(response.redirectUri)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        window.location.href = response.redirectUri;
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _a.sent();
                        if (error_1.responseStatus && error_1.responseStatus.errorCode) {
                            if (error_1.responseStatus.errorCode == 'ArgumentException') {
                                that.alert = new Alert(error_1.responseStatus.message, 'danger');
                                return [2 /*return*/];
                            }
                        }
                        // let default error handler handle unknown errors.
                        throw error_1;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ConsentComponent.prototype.buildScopeGroups = function (resourceScopes) {
        var scopesWithDescription = resourceScopes.filter(function (s) { return s.description && s.displayName.indexOf('-') > 0; });
        var scopesWithoutDescription = resourceScopes.filter(function (s) { return !s.description || s.displayName.indexOf('-') <= 0; });
        var scopeNames = scopesWithDescription.map(function (s) { return s.name.split(':')[0]; });
        var unique = function (value, index, self) {
            return self.indexOf(value) === index;
        };
        var uniqueScopeNames = scopeNames.filter(unique);
        var scopeGroups = [];
        var that = this;
        uniqueScopeNames.forEach(function (scope) {
            var group = that.buildScopeGroup(scope, resourceScopes.filter(function (s) { return s.name.startsWith(scope); }));
            scopeGroups.push(group);
        });
        if (scopesWithoutDescription && scopesWithoutDescription.length > 0) {
            var otherGroup = this.buildOtherGroup(scopesWithoutDescription);
            scopeGroups.push(otherGroup);
        }
        return scopeGroups.filter(function (s) { return s.scopes && s.scopes.length > 0; });
    };
    ConsentComponent.prototype.buildScopeGroup = function (type, scopes) {
        if (!scopes || scopes.length === 0) {
            return;
        }
        var isReadAndWrite = scopes && scopes.length === 2;
        var scopeName = scopes[0].displayName.split('-')[0].trim();
        var name = isReadAndWrite ? scopeName + ' - Read & Write' : scopes[0].displayName;
        var description = isReadAndWrite ? scopes[0].description + ' ' + scopes[1].description : scopes[0].description;
        return new ScopeGroupViewModel({
            name: name,
            description: description,
            scopes: scopes
        });
    };
    ConsentComponent.prototype.buildOtherGroup = function (scopes) {
        return new ScopeGroupViewModel({
            name: 'Other',
            description: scopes.map(function (s) { return s.displayName; }).join('<br>'),
            scopes: scopes
        });
    };
    return ConsentComponent;
}());
export { ConsentComponent };
