import { RequestBase } from '../../_common/request-base';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Injectable }             from '@angular/core';
import {PortalClientFactory, PortalResponse}    from '@_services/portal-client-factory';


@Injectable()
export class UserService {
  constructor(private clientFactory: PortalClientFactory) {

  }

  getCurrentUser (): Promise<GetCurrentUserResponse> {
    const emptyUser = new Promise<GetCurrentUserResponse>((resolve, reject) => {
      resolve(null);
    });

    const client = this.clientFactory.createClient();
    const request = new GetCurrentUserRequest();
    if (client) {
      return client.get(request);
    }else {
      return emptyUser;
    }
  }

  updateMemberDetail (request : UpdateMemberDetail): Promise<PortalResponse> {
    const client = this.clientFactory.createClient();

    return client.post(request);
  }

  changePassword (request : ChangePassword): Promise<PortalResponse> {
    const client = this.clientFactory.createClient();

    return client.post(request);
  }

  changeEmail (request : ChangeEmail) : Promise<PortalResponse> {
    const client = this.clientFactory.createClient();

    return client.post(request);
  }

  activateEmail (request : ActivateEmail) : Promise<PortalResponse> {
    const client = this.clientFactory.createClient();

    return client.post(request);
  }
}


// @Route("/user/current-user")
export class GetCurrentUserRequest extends RequestBase<GetCurrentUserResponse> {
  getTypeName() { return 'GetCurrentUserRequest'; }
}

export class GetCurrentUserResponse {
  MemberId  : string;
  FirstName : string;
  LastName  : string;
  CompanyName : string;
  RegisteredEmail : string;
  WorkPhone : string;
  MobilePhone  : string;
  PhotoDocumentId : string;
  RegisteredOn  : Date;
}



// @Route("/user/update-detail")
export class UpdateMemberDetail extends RequestBase<PortalResponse> {
    getTypeName() { return 'UpdateMemberDetail'; }
}

// @Route("/user/change-password")
export class ChangePassword extends RequestBase<PortalResponse> {
  CurrentPassword : string;
  NewPassword: string;
  getTypeName() { return 'ChangePassword'; }
}

// @Route("/user/change-email")
export class ChangeEmail extends RequestBase<PortalResponse> {
  CurrentEmail : string;
  NewEmail: string;
  getTypeName() { return 'ChangeEmail'; }
}

// @Route("/user/activate-email")
export class ActivateEmail extends RequestBase<PortalResponse> {
  ActivationId : string;
   getTypeName() { return 'ActivateEmail'; }
}
