import { Injectable } from '@angular/core';
import { PortalClientFactory } from '@_services/portal-client-factory';
import { ErrorResponse, ErrorRequest } from '@app/app.dtos';

@Injectable()
export class ErrorService {

  constructor(private clientFactory: PortalClientFactory) {
  }

  getErrorDetails(errorId: string): Promise<ErrorResponse> {
    const emptyResponse = new Promise<ErrorResponse>((resolve, reject) => {
      resolve();
    });

    const client = this.clientFactory.createClient(false);
    let request = new ErrorRequest();
    request.errorId = errorId;
    if (client) {
      return client.post(request);
    } else {
      return emptyResponse;
    }
  }
}
