import { Component, OnInit }                    from '@angular/core';
import { NgbModal, NgbModalRef }                from '@ng-bootstrap/ng-bootstrap';
import { UserService, GetCurrentUserResponse } from '../services/user.service';
import { EditComponent }                        from '../edit/edit.component';
import { ChangePasswordDialogComponent }        from './change-password-dialog/change-password-dialog.component';
import { ChangeEmailDialogComponent }           from './change-email/change-email-dialog.component';
import { AppComponent }                         from '../../app.component';
import { Alert }                                from '../../model/alert';
import { PortalSession }                        from '../../_common/portal-session';
import { UtilityService } from '../../_common/utility.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private userService : UserService,
              private modalService: NgbModal,
              private rootComp: AppComponent,
              private utility: UtilityService,
              private portalSession: PortalSession) {
    this.rootComp.showNavbar();
  }

  member : GetCurrentUserResponse;
  popupRef: NgbModalRef;
  isLoaded = false;
  alert : Alert;
  avatarUrl: string;
  
  ngOnInit() {

    this.load();
  }

  load() {
      this.avatarUrl = 'user/avatar.png?ImageSize=L';
    this.userService.getCurrentUser()
      .then(r => {
        this.member = r;
        if (this.member) {

            if (this.member.MemberId) {
                this.avatarUrl += '&MemberId=' + this.member.MemberId;
            }
        } 
        this.isLoaded = true;
      });
  }

  edit() {
    const that = this;
      that.alert = null;
    if (!that.popupRef) {
        that.popupRef = that.modalService.open(EditComponent, { size: 'lg'});

        that.popupRef.result
        .then(() => {
            that.popupRef = null;
            that.load();
        }, () => {that.popupRef = null; })
        .catch(() => that.popupRef = null);
    }
  }

  changePassword() {
    const that = this;
      that.alert = null;

    if (!that.popupRef) {
        that.popupRef = that.modalService.open(ChangePasswordDialogComponent, { size: 'lg'});

        that.popupRef.result
        .then(() => {
          that.popupRef = null;
        }, () => { that.popupRef = null; })
        .catch(err => {
          that.popupRef = null
          if (err.responseStatus) {
            that.alert = new Alert(err.responseStatus.message, 'info');
          }
        });

    }
  }

  changeEmail() {
      const that = this;
      that.alert = null;
    if (!that.popupRef) {
        that.popupRef = that.modalService.open(ChangeEmailDialogComponent, { size: 'lg'});

        that.popupRef.result
        .then((newEmail) => {
            that.popupRef = null;

          const message = 'An activation email has been sent to ' + newEmail + '. Follow the instructions in this email to continue.';
            that.alert = new Alert(message, 'info');
        }, () => { that.popupRef = null; })
        .catch(err => {
            that.popupRef = null;
          if (err.responseStatus) {
              that.alert = new Alert(err.responseStatus.message, 'danger');
          }
        });

    }
  }
 
}
