import {FormatTimePipe} from './_pipes/format-time';
import {UtilityService} from './_common/utility.service';
import {IPortalStorage, PortalLocalStorage, PortalCookieStorage} from './_common/portal-storage-index';
import {PortalSession} from './_common/portal-session';
import {CurrencyPipe} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ToastyModule} from 'ng2-toasty';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';
import {routing} from './app.routing';
import {SignInComponent} from './sign-in/sign-in.component';
import {AuthGuard} from './_guards/index';
import {AuthenticationService} from '@_services/authentication.service';
import {PortalErrorHandlerService} from '@_services/portal-error-handler.service';
import {RouterExtService} from '@_services/router-ext.service';
import {TestService} from '@_services/test.service';
import {PortalClientFactory} from '@_services/portal-client-factory';
import {ClientFactoryService} from '@_services/client-factory.service';
import {NavigationComponent, NavCardComponent} from './navigation/index';
import {ToastMessageComponent} from './messages/index';
import {ErrorAlertComponent} from './error-alert/error-alert.component';
import {ErrorComponent} from './error-alert/error.component';
import {ConnectDialogComponent} from './connect-dialog/connect-dialog.component';
import {AppWideEventService} from '@_services/app-wide-events.service';
import {ToastMessageService} from '@_services/toast-message.service';
import {
  ProfileComponent,
  ProfileCardComponent,
  UserService,
  ChangePasswordDialogComponent,
  ChangeEmailDialogComponent
} from './user/index';
import {
  GetInitialsPipe,
  ServerUrlPipe,
  ManagerAppUrlPipe,
  PMCurrencyPipe,
  LocalDatePipe,
  InspecitonTypePipe,
  DaysInArrearsPipe,
  AddressTextPipe,
  FormatDatePipe,
  FormatFileSizePipe,
  SafeHtmlPipe
} from './_pipes/index';
import {EditComponent} from './user/edit/edit.component';
import {FormFieldComponent} from './_components/form-field/form-field.component';
import {SignUpEmailComponent} from './sign-up/email/email.component';
import {SignUpService} from './sign-up/services/sign-up.service';
import {LoadingComponent} from './_components/loading/loading.component';
import {HomeComponent} from './home/home.component';
import {AboutComponent, HelpComponent, TermsComponent} from './layout/index';
import {CommonActionsComponent} from './_components/common-actions/common-actions.component';
import {
  ConsentComponent, ConsentService
} from './consent/index';
import {ForgottenPasswordComponent} from './forgotten-password/forgotten-password.component';
import {ResetPasswordService} from './forgotten-password/services/resetpassword.service';
import {CopyrightComponent} from './_components/copyright/copyright.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {PinVerificationComponent} from './sign-in/components/pin-verification/pin-verification.component';
import {TfaSetupComponent} from './sign-in/components/tfa-setup/tfa-setup.component';
import {ErrorService} from './error-alert/services/error.service';
import {QRCodeModule} from 'angular2-qrcode';
import {NotFoundComponent} from './error-alert/not-found/not-found.component';
import {BowserModule, BowserService} from 'ngx-bowser';
import {SignInService} from "@app/sign-in/services/sign-in.service";

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    NavigationComponent,
    NavCardComponent,
    ErrorAlertComponent,
    ErrorComponent,
    ConnectDialogComponent,
    ProfileComponent,
    GetInitialsPipe,
    ServerUrlPipe,
    ManagerAppUrlPipe,
    PMCurrencyPipe,
    LocalDatePipe,
    FormatDatePipe,
    InspecitonTypePipe,
    DaysInArrearsPipe,
    FormatTimePipe,
    AddressTextPipe,
    FormatFileSizePipe,
    SafeHtmlPipe,
    EditComponent,
    FormFieldComponent,
    SignUpEmailComponent,
    LoadingComponent,
    HomeComponent,
    AboutComponent,
    HelpComponent,
    TermsComponent,
    ChangePasswordDialogComponent,
    ChangeEmailDialogComponent,
    ProfileCardComponent,
    CommonActionsComponent,
    ToastMessageComponent,
    ConsentComponent,
    CopyrightComponent,
    ForgottenPasswordComponent,
    ResetPasswordComponent,
    PinVerificationComponent,
    TfaSetupComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    routing,
    NgbModule.forRoot(),
    ToastyModule.forRoot(),
    QRCodeModule,
    BowserModule
  ],
  providers: [
    CurrencyPipe,
    PortalErrorHandlerService,
    {provide: LOCALE_ID, useValue: 'en-au'},
    {
      provide: ErrorHandler,
      useClass: PortalErrorHandlerService
    },
    {provide: PortalClientFactory, useClass: ClientFactoryService},
    AuthGuard,
    AuthenticationService,
    TestService,
    AppWideEventService,
    UserService,
    ConsentService,
    SignUpService,
    PortalLocalStorage,
    PortalCookieStorage,
    PortalSession,
    UtilityService,
    ToastMessageService,
    RouterExtService,
    ResetPasswordService,
    ErrorService,
    BowserService,
    SignInService
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    ErrorAlertComponent,
    ConnectDialogComponent,
    SignUpEmailComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
