<div class="container h-100 d-flex justify-content-center">
  <div class="d-flex flex-column my-auto">
    <form autocomplete="off"
          class="mx-auto p-4 card d-flex flex-column"
          id="pin-verification-form"
          (ngSubmit)="f.form.valid && verify()"
          [ngClass]="{'was-validated': f.submitted}"
          name="form" #f="ngForm">
      <div>
        <div class="text-center">
          <img class="non-retina mx-auto" src="assets/img/brand.png" style="height:80px" alt="PropertyMe">
          <img class="retina mx-auto" src="assets/img/brand-big.png" style="height:80px" alt="PropertyMe">
        </div>
      </div>
      <div class="form-group" [ngClass]="{ 'has-danger' : f.submitted && !input.valid }">
        <h4 class="mt-4">Authentication Code</h4>
        <p>Please enter 6-digit code from the Google Authenticator app</p>
        <input autocomplete="off" type="tel" class="form-control" name="OneTimePinCode" [(ngModel)]="oneTimePinCode" #input="ngModel" required placeholder="6-digit number" maxlength="6" />
        <div *ngIf="f.submitted && !input.valid" class="invalid-feedback">6-digit code required</div>
      </div>
      <ngb-alert *ngIf="alert" [type]="alert.type" (close)="alert = null">
        <div class="card-text card-smaller-text text-left">
          <i class="fas fa-exclamation-circle"></i> {{alert.message}}
        </div>
      </ngb-alert>

      <div class="mt-auto mb-2 d-flex justify-content-between">
        <a class="my-auto" href="" (click)="backToSignIn()">Back to sign in</a>
        <button [disabled]="loading" class="btn btn-primary justify-content-space-between" type="submit">Verify</button>
      </div>
    </form>
    <copyright class="mb-2"></copyright>
  </div>
</div>
