import { Component, OnInit } from '@angular/core';
import { NgbActiveModal }        from '@ng-bootstrap/ng-bootstrap';
import { UserService, ChangeEmail } from '../../services/user.service';
import { Alert } from '../../../model/alert';
@Component({
  selector: 'app-change-email-dialog',
  templateUrl: './change-email-dialog.component.html',
  styleUrls: ['./change-email-dialog.component.scss']
})
export class ChangeEmailDialogComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private userService : UserService
  ) { }

  dto : ChangeEmail;
  isSaving  : boolean;
  isLoaded = false;
  alert : Alert;

  ngOnInit() {
    this.dto = new ChangeEmail();
    this.isLoaded = true;
  }

  save(form) {
    this.isSaving = true;
    if (!form.valid) {
      for (const control of form.controls) {
        control.markAsDirty();
      }
      this.isSaving = false;
      return;
    }

    if (this.dto.CurrentEmail === this.dto.NewEmail) {
      this.alert = new Alert('The new email is same as current email', 'danger');
      this.isSaving = false;
      return;
    }

    if (!this.isValidEmail(this.dto.NewEmail)) {
      this.alert = new Alert('The new email is invalid', 'danger');
      this.isSaving = false;
      return;
    }

    this.userService.changeEmail(this.dto)
      .then(r => {
        this.isSaving = false;
        this.activeModal.close(this.dto.NewEmail);
      })
      .catch(err => {
        this.isSaving = false;
        if (err.responseStatus) {
          this.alert = new Alert(err.responseStatus.message, 'danger');
        }
      });
  }

  private isValidEmail(email) {
    const testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (testEmail.test(email)) {
      return true;
    }else {
      return false;
    }
  }

}
