<form class="mx-auto" #form="ngForm" (ngSubmit)="save(form)" novalidate>
  <div class="modal-header">
    <h4 class="modal-title text-primary">
      <i class="icon icon-publish icon-lg"></i> Connect With Your Property</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="!isLoaded">
    <div class="text-center pt-5">
      <strong class="text-info">
        <i class="icon icon-light-up fast-right-spinner"></i> Loading...
      </strong>
    </div>
  </div>
  <div class="modal-body" *ngIf="isLoaded && !isEmailSent">
    <p class=""><strong>
      PropertyMe gives you access to your property information if you're a tenant or an owner.
      It connects to PropertyMe management software used by your property manager.
    </strong></p>

    <h5 class="text-primary">Required to connect:</h5>
    <ul>
      <li>your property manager is using the PropertyMe system and granted you access</li>
      <li>you have provided your property manager an email address</li>
      <li>you have access to this email account</li>
    </ul>

    <p class="text-primary"><strong>
     Enter your email address
    </strong></p>

    <p class="text-danger" *ngIf="error">
      {{error}}
    </p>

    <input class="form-control" type="email" name="Email" required [(ngModel)]="dto.Email" placeholder="your email address">

    <p class="text-muted"><strong>
       An activation email will be sent to this email address.
      </strong></p>

    <div class="modal-footer">
      <button class="btn btn-outline-primary float-right" type="button" (click)="activeModal.dismiss('cancel')">Cancel</button>
      <button name="register-button" class="btn btn-outline-primary float-right" type="submit">
          <i class="icon-envelope"></i>
          Email Me
      </button>
    </div>
  </div>

  <div class="modal-body" *ngIf="isEmailSent">
    <p class="lead text-primary" >
      An activation email has been sent to <strong>{{dto.Email}}</strong>.
    </p>

    <p class="lead text-primary" >
      Follow the instructions in this email to continue.
    </p>

    <div class="modal-footer">
      <button class="btn btn-link float-right" type="button" (click)="activeModal.dismiss('cancel')">Close</button>
    </div>
  </div>

</form>

