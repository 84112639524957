import {
  ProcessConsentResponse,
  GetConsentRequest,
  GetConsentResponse,
  ProcessConsentRequest,
  ScopeViewModel
} from '@app/app.dtos';
import { RequestBase } from '../../_common/request-base';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Injectable } from '@angular/core';
import { PortalClientFactory } from '@_services/portal-client-factory';


@Injectable()
export class ConsentService {
  constructor(private clientFactory: PortalClientFactory) {

  }

  getConsent(returnUrl: string): Promise<GetConsentResponse> {
    const emptyResponse = new Promise<GetConsentResponse>((resolve, reject) => {
      resolve(null);
    });

    const client = this.clientFactory.createClient(false);
    let request = new GetConsentRequest();
    request.returnUrl = returnUrl;
    if (client) {
      return client.get(request);
    } else {
      return emptyResponse;
    }
  }

  processConsent(
    isAllowing: boolean,
    scopesConsented: string[],
    returnUrl: string,
    customerId: string
  ): Promise<ProcessConsentResponse> {
    const emptyResponse = new Promise<ProcessConsentResponse>((resolve, reject) => {
      resolve(null);
    });

    let request = new ProcessConsentRequest();
    request.isAllowing = isAllowing;
    request.scopesConsented = scopesConsented;
    request.returnUrl = returnUrl;
    request.customerId = customerId;

    const client = this.clientFactory.createClient(false);
    if (client) {
      return client.post(request);
    } else {
      return emptyResponse;
    }
  }
}

export class ScopeGroupViewModel
{
  public name: string;
  public description: string;
  public scopes: ScopeViewModel[];

  constructor(
      fields?: {
        name?: string;
        description?: string;
        scopes?: ScopeViewModel[];
      }) {
    if (fields) Object.assign(this, fields);
  }
}
