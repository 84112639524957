import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {

  constructor() { }

  year: string;
  version: string;

  ngOnInit() {
    this.year = new Date().getFullYear().toString();
    this.version = environment.version;
  }

}
