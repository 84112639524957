export const environment = {
  production: true,
  serverUrl: '/api/',
  managerAppUrl: 'https://app.propertyme.com/',
  memberPortalUrl: 'https://my.propertyme.com/',
  raygunKey: 'VG2FUTfe551ZXKRlPvemeA',
  sessionDuration: 29,
  version: '2024.0904.01-master',
  uscHostName: 'app'
};

export function sleep(ms = 0) {
  return new Promise(r => setTimeout(r, ms));
}
