import { ForgottenPasswordRequest, ValidatePasswordResetRequest, ResetPasswordResponse, ResetPasswordRequest } from '@app/app.dtos';;
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Injectable } from '@angular/core';
import { PortalClientFactory } from '@_services/portal-client-factory';

@Injectable()
export class ResetPasswordService {
  constructor(private clientFactory: PortalClientFactory) {
  }

  forgottenPassword(email: string, returnUrl: string) : Promise<void> {
    const emptyResponse = new Promise<void>((resolve, reject) => {
      resolve();
    });

    const client = this.clientFactory.createClient(false);
    let request = new ForgottenPasswordRequest();
    request.email = email;
    request.returnUrl = returnUrl;
    if (client) {
      return client.post(request);
    } else {
      return emptyResponse;
    }
  }

  validatePasswordReset(id: string) : Promise<void> {
    const emptyResponse = new Promise<void>((resolve, reject) => {
      resolve();
    });

    const client = this.clientFactory.createClient(false);
    let request = new ValidatePasswordResetRequest();
    request.id = id;
    if (client) {
      return client.get(request);
    } else {
      return emptyResponse;
    }
  }

  resetPassword(id: string, password: string, confirmPassword: string): Promise<ResetPasswordResponse> {
    const emptyResponse = new Promise<ResetPasswordResponse>((resolve, reject) => {
      resolve(null);
    });

    const client = this.clientFactory.createClient(false);
    let request = new ResetPasswordRequest();
    request.id = id;
    request.password = password;
    request.confirmPassword = confirmPassword;
    if (client) {
      return client.post(request);
    } else {
      return emptyResponse;
    }
  }
}
