import { Injectable } from '@angular/core';
import {GetConsentRequest, InitSignInRequest, InitSignInResponse} from "@app/app.dtos";
import {PortalClientFactory} from "@app/_services";
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class SignInService {

  private returnUrl:string;

  constructor(
    private clientFactory: PortalClientFactory
  ) { }

  async initSignIn(returnUrl: string) : Promise<InitSignInResponse> {
    const client = this.clientFactory.createClient(false);

    let request =  new InitSignInRequest({
      returnUrl: returnUrl
    });

    return await client.get(request);
  }
}
