import { AuthenticateResponse, Authenticate } from '@app/app.dtos';;
import { UtilityService } from '../_common/utility.service';
import { PortalSession, SessionInfo } from '../_common/portal-session';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { PortalClientFactory } from '@_services/portal-client-factory';
import { AppWideEventService } from '@_services/app-wide-events.service';
import { Moment } from 'moment';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { map } from 'rxjs/operator/map';
import { toPromise } from 'rxjs/operator/toPromise';
import * as rg4js from 'raygun4js';

@Injectable()
export class AuthenticationService {
  constructor(
    private clientFactory: PortalClientFactory,
    private eventService: AppWideEventService,
    private portalSession: PortalSession,
    private utility: UtilityService,
    private router: Router) { }

  async login(username: string, password: string, returnUrl: string, meta: any = null, refresh = false): Promise<void> {
    // remove user from local storage to log user out
    this.portalSession.removeSession();
    const request = new Authenticate();
    request.provider = 'credentials'; // Username/Password provider
    request.userName = username;
    request.password = password;
    request.continue = returnUrl;
    request.meta = meta;

    const client = this.clientFactory.createClient(true);

    const that = this;

    let authenResponse = await client.post(request);
    if (authenResponse && authenResponse.referrerUrl) {
      rg4js('setUser', {
        identifier: authenResponse.userName,
        isAnonymous: false,
        email: authenResponse.userName,
        fullName: authenResponse.displayName
      });

      // Add the username to the cookie
      this.setUserName(authenResponse.userName);

      if (authenResponse.referrerUrl) {
        if (authenResponse.referrerUrl.startsWith('/') && !authenResponse.referrerUrl.startsWith('/connect')) {
          await this.router.navigateByUrl(authenResponse.referrerUrl);
        } else {
          window.location.href = authenResponse.referrerUrl;
        }
      }
    }
  }

  setUserName(userName: string) {
    var cookieName = 'usc-' + environment.uscHostName;
    var cookieValue = (userName).toLowerCase().trim();
    var domain = '';
    var host = location.hostname.toLowerCase();
    if (host !== 'localhost' && host !== '127.0.0.1') {
      domain = '.propertyme.com';
    }
    var encoded = encodeURIComponent(btoa(cookieValue));
    var cookieText = cookieName + '=' + encoded + ';domain=' + domain;
    document.cookie = cookieText;
  };

  logout(removeSession: boolean = true) {
    // remove user from local storage to log user out
    if (removeSession) {
      this.portalSession.removeSession();
    }

    const client = this.clientFactory.createClient(true);
    const request = new Authenticate();
    request.provider = 'logout';

    if (client) {
      return client.post(request);
    } else {
      return new Promise((resolve, reject) => {
        resolve(null);
      }).then(() => {
      });
    }
  }

  // please use this.portalSession.defaultAgent
  loadSession(): SessionInfo {
    return this.portalSession.session;
  }

  setServerUrlCookie() {
    document.cookie = 'serverUrl=' + environment.serverUrl;
  }


}
