import { Component, OnInit }    from '@angular/core';
import { NgbActiveModal}        from '@ng-bootstrap/ng-bootstrap';
import {
  UserService,
  GetCurrentUserResponse,
  UpdateMemberDetail } from '../services/user.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private userService : UserService ) { }

  public dto : UpdateMemberDetail;

  isSaving  : boolean;
  isLoaded = false;

  ngOnInit() {
    this.dto = new UpdateMemberDetail();

    this.userService.getCurrentUser()
      .then(r => {
        Object.assign(this.dto, r);
        this.isLoaded = true;
      });
      // .catch(err => {
      //   // done
      // });
  }

  save(form) {
    if (form.valid) {
      this.isSaving = true;
      this.userService.updateMemberDetail(this.dto)
        .then(r => {
          this.isSaving = false;
          this.activeModal.close();
        });
      // .catch(err => {
      //   console.log(err);
      //   this.isSaving = false;
      // });
    }
  }

}
