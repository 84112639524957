import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ResetPasswordService } from './services/resetpassword.service';
import { Alert } from '../model/alert';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {
  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resetPasswordService: ResetPasswordService
  ) { }

  model: any = {};
  loading: boolean;
  alert: Alert;

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.route.snapshot.queryParams['ReturnUrl'] || '/';
  }

  async forgottenPassword() {
    const that = this;
    that.alert = null;
    try {
      that.loading = true;
      const userName = that.model.username || '';
      await that.resetPasswordService.forgottenPassword(userName, that.returnUrl);
      that.alert = new Alert('You will soon receive an email in your ' + userName + ' inbox with instructions to activate your account.', 'success');
    } catch (error) {
      that.loading = false;
      if (error.responseStatus && error.responseStatus.errorCode) {
        if (error.responseStatus.errorCode === 'ArgumentException' || error.responseStatus.errorCode === 'Email') {
          that.alert = new Alert(error.responseStatus.message, 'danger');
          return;
        }
      }
      // let default error handler handle unknown errors.
      throw error;
    }
  }
}
