var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
//import { JsonServiceClient, ResponseStatus }  from 'servicestack-client';
import { JsonServiceClient } from '@servicestack/client';
var PortalServiceClient = /** @class */ (function (_super) {
    __extends(PortalServiceClient, _super);
    function PortalServiceClient(baseUrl, portalSession) {
        var _this = _super.call(this, baseUrl) || this;
        _this.portalSession = portalSession;
        return _this;
    }
    PortalServiceClient.prototype.get = function (request, args) {
        if (typeof (request) !== 'string') {
            var myNewRequest = request;
            if (myNewRequest !== undefined && myNewRequest.FolioId !== undefined) {
                // still let it could request another folio rather than current folio
                if (myNewRequest.FolioId === null || myNewRequest.FolioId.length < 36) {
                    // reset session currentFolioId when it's null
                    var session = this.portalSession.session;
                    return _super.prototype.get.call(this, myNewRequest, args);
                }
            }
        }
        return _super.prototype.get.call(this, request, args);
    };
    return PortalServiceClient;
}(JsonServiceClient));
export { PortalServiceClient };
var PortalClientFactory = /** @class */ (function () {
    function PortalClientFactory() {
    }
    PortalClientFactory.prototype.createClient = function (ignore401) {
        if (ignore401 === void 0) { ignore401 = false; }
        return null;
    };
    return PortalClientFactory;
}());
export { PortalClientFactory };
var PortalResponse = /** @class */ (function () {
    function PortalResponse() {
    }
    return PortalResponse;
}());
export { PortalResponse };
