import { Component, OnInit } from '@angular/core';
import { NgbActiveModal }        from '@ng-bootstrap/ng-bootstrap';
import { UserService, ChangePassword } from '../../services/user.service';
import { Alert } from '../../../model/alert';
@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private userService : UserService
  ) { }

  dto : ChangePassword;
  isSaving  : boolean;
  isLoaded = false;
  confirmPassword : string;
  alert : Alert;

  ngOnInit() {
    this.dto = new ChangePassword();
    this.isLoaded = true;
  }

  save(form) {
    this.isSaving = true;
    if (!form.valid) {
      for (const control of form.controls) {
        control.markAsDirty();
      }
      this.isSaving = false;
      return;
    }

    if (this.dto.NewPassword !== this.confirmPassword) {
      this.alert = new Alert('Passwords do not match', 'danger');
      this.isSaving = false;
      return;
    }

    if (this.dto.NewPassword === this.dto.CurrentPassword) {
      this.alert = new Alert('The new password is same as current password', 'danger');
      this.isSaving = false;
      return;
    }

    this.userService.changePassword(this.dto)
      .then(r => {
        this.isSaving = false;
        this.activeModal.close();
      })
      .catch(err => {
        this.isSaving = false;
        if (err.responseStatus) {
          this.alert = new Alert(err.responseStatus.message, 'danger');
        }
      });
  }

}
