var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RequestBase } from '../../_common/request-base';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { PortalClientFactory, PortalResponse } from '@_services/portal-client-factory';
var SignUpService = /** @class */ (function () {
    function SignUpService(clientFactory) {
        this.clientFactory = clientFactory;
    }
    SignUpService.prototype.postMemberSignUp = function (dto) {
        var client = this.clientFactory.createClient();
        return client.post(dto);
    };
    SignUpService.prototype.getSignUpActivation = function (activationId) {
        var emptySignUpActivation = new Promise(function (resolve, reject) {
            resolve(null);
        });
        var client = this.clientFactory.createClient();
        var request = new GetPortalActivation();
        request.ActivationId = activationId;
        if (client) {
            return client.get(request);
        }
        else {
            return emptySignUpActivation;
        }
    };
    SignUpService.prototype.postSignUpActivation = function (dto) {
        var client = this.clientFactory.createClient();
        return client.post(dto);
    };
    return SignUpService;
}());
export { SignUpService };
// [Route("/user/signup")]
var RequestSignUpMember = /** @class */ (function (_super) {
    __extends(RequestSignUpMember, _super);
    function RequestSignUpMember() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RequestSignUpMember.prototype.getTypeName = function () { return 'RequestSignUpMember'; };
    return RequestSignUpMember;
}(RequestBase));
export { RequestSignUpMember };
// [Route("/user/activate/{ActivationId}", "GET")]
var GetPortalActivation = /** @class */ (function (_super) {
    __extends(GetPortalActivation, _super);
    function GetPortalActivation() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GetPortalActivation.prototype.getTypeName = function () { return 'GetPortalActivation'; };
    return GetPortalActivation;
}(RequestBase));
export { GetPortalActivation };
// [Route("/user/activate/{ActivationId}", "POST")]
var ActivatePortalSignUpMember = /** @class */ (function (_super) {
    __extends(ActivatePortalSignUpMember, _super);
    function ActivatePortalSignUpMember() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ActivatePortalSignUpMember.prototype.getTypeName = function () { return 'ActivatePortalSignUpMember'; };
    return ActivatePortalSignUpMember;
}(RequestBase));
export { ActivatePortalSignUpMember };
var SignUpMemberResponse = /** @class */ (function (_super) {
    __extends(SignUpMemberResponse, _super);
    function SignUpMemberResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SignUpMemberResponse;
}(PortalResponse));
export { SignUpMemberResponse };
