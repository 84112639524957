import { RequestBase } from '../../_common/request-base';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Injectable }                           from '@angular/core';
import {PortalClientFactory, PortalResponse}    from '@_services/portal-client-factory';

@Injectable()
export class SignUpService {
  constructor(private clientFactory: PortalClientFactory) {

  }

  postMemberSignUp(dto : RequestSignUpMember): Promise<SignUpMemberResponse> {
    const client = this.clientFactory.createClient();
    return client.post(dto);
  }

  getSignUpActivation (activationId : string): Promise<ActivatePortalSignUpMember> {

    const emptySignUpActivation = new Promise<ActivatePortalSignUpMember>((resolve, reject) => {
      resolve(null);
    });

    const client = this.clientFactory.createClient();
    const request = new GetPortalActivation();
    request.ActivationId = activationId;
    if (client) {
      return client.get(request);
    }else {
      return emptySignUpActivation;
    }
  }

  postSignUpActivation (dto : ActivatePortalSignUpMember): Promise<PortalResponse> {
    const client = this.clientFactory.createClient();
    return client.post(dto);
  }

}

// [Route("/user/signup")]
export class RequestSignUpMember extends RequestBase<SignUpMemberResponse> {
  Email     : string;
  FirstName : string;
  LastName  : string;
  getTypeName() { return 'RequestSignUpMember'; }
}

// [Route("/user/activate/{ActivationId}", "GET")]
export class GetPortalActivation extends RequestBase<ActivatePortalSignUpMember> {
   ActivationId : string;
   getTypeName() { return 'GetPortalActivation'; }
}

// [Route("/user/activate/{ActivationId}", "POST")]
export class ActivatePortalSignUpMember extends RequestBase<PortalResponse> {
   ActivationId  : string;
   Email : string;
   Password : string;
   FirstName : string;
   LastName : string;
   CompanyName : string;
   WorkPhone : string;
   MobilePhone : string;
   getTypeName() { return 'ActivatePortalSignUpMember'; }
}

export class SignUpMemberResponse extends PortalResponse {
  ActivationId : string;
}

