import { Router } from '@angular/router';
import { Component, OnInit }    from '@angular/core';
import { NgbActiveModal}        from '@ng-bootstrap/ng-bootstrap';
import {
  SignUpService,
  RequestSignUpMember,
  ActivatePortalSignUpMember } from '../services/sign-up.service';

@Component({
  selector: 'sign-up-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class SignUpEmailComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private signUpService : SignUpService,
    private router : Router ) { }

  public dto : RequestSignUpMember;

  isEmailSent : boolean;
  attemptedSave : boolean;
  isSaving  : boolean;
  error : string;
  isLoaded = false;


  ngOnInit() {
    this.dto = new RequestSignUpMember();
    this.isLoaded = true;
    this.isSaving = false;
  }

  save(form) {
    this.attemptedSave = true;
    if (form.valid) {
      this.isSaving = true;
      this.signUpService.postMemberSignUp(this.dto)
        .then(r => {
          this.isSaving = false;
          this.isEmailSent = true;
          if (r.ActivationId) {
            this.activeModal.dismiss('cancel');
            this.router.navigate(['/activate/' + r.ActivationId]);
          }
        })
      .catch(err => {
        if (err.responseStatus) {
          this.error =  err.responseStatus.message;
        }
        console.log(JSON.stringify(err));
        this.isSaving = false;
      });
    }
  }

}
