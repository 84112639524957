<div class="container h-100">
  <div class="row h-100 align-items-center">
    <div class="col-sm text-center">
      <img class="non-retina" src="assets/img/brand.png" style="height:100px" alt="PropertyMe">
      <p><i class="mx-2 fas fa-exclamation-circle"></i> An unexpected error has been encountered.</p>
      <div class="alert alert-warning mx-auto text-left" *ngIf="error">
        <dl>
          <dt *ngIf="error.displayMode">displayMode:</dt>
          <dd *ngIf="error.displayMode">{{error.displayMode}}</dd>
          <dt *ngIf="error.error">error:</dt>
          <dd *ngIf="error.error">{{error.error}}</dd>
          <dt *ngIf="error.errorDescription">errorDescription:</dt>
          <dd *ngIf="error.errorDescription">{{error.errorDescription}}</dd>
          <dt *ngIf="error.redirectUri">redirectUri:</dt>
          <dd *ngIf="error.redirectUri">{{error.redirectUri}}</dd>
          <dt *ngIf="error.requestId">requestId:</dt>
          <dd *ngIf="error.requestId">{{error.requestId}}</dd>
          <dt *ngIf="error.responseMode">responseMode:</dt>
          <dd *ngIf="error.responseMode">{{error.responseMode}}</dd>
          <dt *ngIf="error.uiLocales">uiLocales:</dt>
          <dd *ngIf="error.uiLocales">{{error.uiLocales}}</dd>
        </dl>
      </div>
      <span class="text-center" *ngIf="redirectMsg">{{redirectMsg}}</span>
    </div>
  </div>
</div>
