<div class="container h-100 d-flex justify-content-center">
  <div class="d-flex flex-column my-auto">
    <form autocomplete="off"
          class="mx-auto p-4 card d-flex flex-column"
          id="reset-password-form"
          (ngSubmit)="f.form.valid && resetPassword()"
          [ngClass]="{'was-validated': f.submitted}"
          name="form" #f="ngForm">
      <div>
        <div class="text-center">
          <img class="non-retina mx-auto" src="assets/img/brand.png" style="height:80px" alt="PropertyMe">
          <img class="retina mx-auto" src="assets/img/brand-big.png" style="height:80px" alt="PropertyMe">
        </div>
      </div>
      <div class="form-group" [ngClass]="{ 'has-danger' : f.submitted && !password.valid }">
        <h4 class="my-2">Reset Password</h4>
        <ul>
          <li>Password must contain at least 10 characters.</li>
        </ul>
        <input [disabled]="loading" autocomplete="off" type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required placeholder="New Password" />
        <div *ngIf="f.submitted && !password.valid" class="invalid-feedback">New password is required</div>
      </div>
      <div class="form-group" [ngClass]="{ 'has-danger' : f.submitted && !confirmPassword.valid }">
        <input [disabled]="loading" autocomplete="off" type="password" class="form-control" name="confirmPassword" [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel" required placeholder="Confirm Password" />
        <div *ngIf="f.submitted && !confirmPassword.valid" class="invalid-feedback">Confirm password is required</div>
      </div>
      <ngb-alert *ngIf="alert" [type]="alert.type" (close)="alert = null">
        <div class="card-text card-smaller-text  text-left">
          <i class="fas fa-exclamation-circle"></i> {{alert.message}}
        </div>
      </ngb-alert>

      <div class="mt-auto mb-2 d-flex justify-content-end">
        <button [disabled]="loading" class="btn btn-primary justify-content-space-between" type="submit">Change Password</button>
      </div>
    </form>
    <copyright class="mb-2"></copyright>
  </div>
</div>
