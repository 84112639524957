var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RequestBase } from '../_common/request-base';
// @Route("/test/ping")
var PingSec = /** @class */ (function (_super) {
    __extends(PingSec, _super);
    function PingSec() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PingSec.prototype.getTypeName = function () { return 'PingSec'; };
    return PingSec;
}(RequestBase));
export { PingSec };
var PingResponse = /** @class */ (function () {
    function PingResponse() {
    }
    return PingResponse;
}());
export { PingResponse };
