var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConnectStatusTypes } from '@_services/connect-status-types';
import { UtilityService } from './_common/utility.service';
import { environment } from '../environments/environment';
import { PortalSession } from './_common/portal-session';
import { AuthenticationService } from '@_services/authentication.service';
import { RouterExtService } from '@_services/router-ext.service';
import { ToastMessageService } from '@_services/toast-message.service';
import { AppWideEventService, MessageEvent } from '@_services/app-wide-events.service';
import { ToastyService } from 'ng2-toasty';
import * as rg4js from 'raygun4js';
import { PortalClientFactory } from "@app/_services";
import { GetConfigRequest } from "@app/app.dtos";
import { PortalLocalStorage } from "@common/portal-storage";
var AppComponent = /** @class */ (function () {
    function AppComponent(eventService, router, location, modalService, portalSession, authenticationService, utilityService, toastyService, toastMessageService, routerExtService, clientFactory, portalStorage) {
        var _this = this;
        this.eventService = eventService;
        this.router = router;
        this.location = location;
        this.modalService = modalService;
        this.portalSession = portalSession;
        this.authenticationService = authenticationService;
        this.utilityService = utilityService;
        this.toastyService = toastyService;
        this.toastMessageService = toastMessageService;
        this.routerExtService = routerExtService;
        this.clientFactory = clientFactory;
        this.portalStorage = portalStorage;
        this.cssClass = '';
        this.isNavbarShown = true;
        this.isLoading = true;
        eventService.unhandledError$.subscribe(function (error) { return _this.displayError(error); });
        eventService.connectStatusEvent$.subscribe(function (event) { return _this.showConnectStatus(event); });
        eventService.checkAppVersionEvent$.subscribe(function (event) { return _this.checkAppVersion(event); });
        eventService.signInEvent$.subscribe(function (event) { return _this.redirectToSignInPage(); });
        eventService.signOutEvent$.subscribe(function (event) { return _this.redirectToSignOutPage(); });
        eventService.messageEvent$.subscribe(function (event) { return _this.toastMessage(event); });
        // override the route reuse strategy: set reuse route to false
        // https://github.com/angular/angular/issues/13831
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.router.events.subscribe(function (evt) {
            if (evt instanceof NavigationEnd) {
                // trick the Router into believing it's last link wasn't previously loaded
                _this.router.navigated = false;
            }
        });
        // listen the toast message position's changes
        this.toastMessageService.position$.subscribe(function (pos) { return _this.toastyComponentPosition = pos; });
    }
    AppComponent.prototype.showNavbar = function () {
        this.cssClass = 'with-top-navbar';
        this.isNavbarShown = true;
    };
    AppComponent.prototype.hideNavbar = function () {
        this.isNavbarShown = false;
    };
    AppComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        return [4 /*yield*/, this.loadAngularRuntimeConfig()];
                    case 1:
                        _a.sent();
                        this.version = environment.version;
                        this.router.events.subscribe(function (event) {
                            if (event instanceof NavigationEnd) {
                                // Track navigation end
                                rg4js('trackEvent', {
                                    type: 'pageView',
                                    path: event.url
                                });
                            }
                            else if (event instanceof NavigationError) {
                                // Track navigation error
                                rg4js('send', {
                                    error: event.error
                                });
                            }
                        });
                        if (this.portalSession && this.portalSession.session) {
                            //  setUser from portalSession
                            rg4js('setUser', {
                                identifier: this.portalSession.session.UserName,
                                firstName: '',
                                fullName: this.portalSession.session.DisplayName
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this.isLoading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.displayError = function (error) {
        if (error.message) {
            this.toastMessage(new MessageEvent('default', 'Service Error', error.message));
        }
        else if (error.errorCode === '401') {
            this.toastMessage(new MessageEvent('default', 'Unauthorized', 'You have signed out.'));
        }
        else if (error.errorCode === '403') {
            this.toastMessage(new MessageEvent('default', 'Forbidden', 'Insufficient permission to perform this action.'));
        }
        else {
            this.toastMessage(new MessageEvent('default', 'Service Error', 'An unexpected remote error occurred. Sorry for any inconvenience.'));
        }
    };
    AppComponent.prototype.toastMessage = function (event) {
        var position = 'bottom-left';
        var toastOptions = {
            title: event.title,
            msg: event.message,
            showClose: true,
            timeout: 10000,
            theme: 'bootstrap',
            onAdd: function (toast) {
                console.log('Toast ' + toast.id + ' has been added!');
            },
            onRemove: function (toast) {
                console.log('Toast ' + toast.id + ' has been removed!');
            }
        };
        this.toastMessageService.setPosition(position);
        switch (event.type) {
            case 'default':
                this.toastyService.default(toastOptions);
                break;
            case 'info':
                this.toastyService.info(toastOptions);
                break;
            case 'success':
                this.toastyService.success(toastOptions);
                break;
            case 'wait':
                this.toastyService.wait(toastOptions);
                break;
            case 'error':
                this.toastyService.error(toastOptions);
                break;
            case 'warning':
                this.toastyService.warning(toastOptions);
                break;
        }
    };
    AppComponent.prototype.showConnectStatus = function (event) {
        if (event.status === ConnectStatusTypes.unreachable) {
            this.router.navigate(['/error'], { queryParams: { returnUrl: this.router.url } });
        }
        else if (event.status === ConnectStatusTypes.offline) {
            // this.showConnectDialog(event);
        }
        else {
            this.toastMessage(new MessageEvent('default', 'Service Error', 'An unexpected remote error occurred. Sorry for any inconvenience.'));
        }
    };
    AppComponent.prototype.loadAngularRuntimeConfig = function () {
        return __awaiter(this, void 0, void 0, function () {
            var that, existingConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        that = this;
                        existingConfig = that.portalStorage.get("angularRuntimeConfig");
                        if (!existingConfig) return [3 /*break*/, 1];
                        Object.assign(environment, JSON.parse(existingConfig));
                        that.loadAngularRuntimeConfigFromRemote().then(); // load in background. no need to wait.
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, that.loadAngularRuntimeConfigFromRemote()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.loadAngularRuntimeConfigFromRemote = function () {
        return __awaiter(this, void 0, void 0, function () {
            var that, client, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        that = this;
                        client = that.clientFactory.createClient();
                        if (!client) return [3 /*break*/, 2];
                        return [4 /*yield*/, client.get(new GetConfigRequest())];
                    case 1:
                        response = _a.sent();
                        // Make sure latest config is loaded.
                        Object.assign(environment, response);
                        that.portalStorage.set('angularRuntimeConfig', JSON.stringify(response));
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    //showConnectDialog(event: ConnectStatusEvent) {
    //  if (!this.popupRef) {
    //    if (event.status === ConnectStatusTypes.unauthorized) {
    //      // The ss-id session cookie is HTTPOnly cookie, have to removed by call signout by server
    //      // Signout only, without clear the client session before sign in
    //      this.authenticationService.logout()
    //        .then(() => {
    //          this.popupRef = this.modalService.open(SignInDialogComponent, { backdrop: 'static', keyboard: false, size: 'sm' });
    //          this.popupRef.result
    //            .then(() => { this.utilityService.refreshCurrentRoute(); })
    //            .catch((reject) => {
    //              console.log(reject);
    //              this.router.navigate(['/sign-in']);
    //            });
    //        })
    //        .catch(() => {
    //          this.router.navigate(['/sign-in']);
    //        });
    //    } else {
    //      if (event.status === ConnectStatusTypes.notPermitted) {
    //        // if current tenant user is disabled portal access, redirect current page to profile
    //        this.router.navigate(['/user/profile']);
    //      }
    //      this.popupRef = this.modalService.open(ConnectDialogComponent, { size: 'sm', windowClass: 'dark-modal' });
    //      this.popupRef.componentInstance.model = new ConnectData(event.status, event.message);
    //    }
    //    if (this.popupRef) {
    //      this.popupRef.result
    //        .then(() => this.popupRef = null)
    //        .catch(() => this.popupRef = null);
    //    }
    //  }
    //}
    AppComponent.prototype.redirectToSignInPage = function () {
        var returnUrl = this.router.url;
        console.log('Current location path ' + returnUrl);
        this.router.navigate(['/sign-in'], { queryParams: { returnUrl: returnUrl } });
    };
    AppComponent.prototype.redirectToSignOutPage = function () {
        var returnUrl = this.router.url;
        console.log('Current location path ' + returnUrl);
        this.router.navigate(['/sign-out'], { queryParams: { returnUrl: returnUrl } });
    };
    AppComponent.prototype.checkAppVersion = function (event) {
        var _this = this;
        this.utilityService.getCurrentAppVersion()
            .then(function (setting) {
            if (setting && setting.Version) {
                if (_this.version !== setting.Version) {
                    window.location.reload();
                }
            }
        });
    };
    return AppComponent;
}());
export { AppComponent };
