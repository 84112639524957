var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// only directly use it for none per folio based services
var RequestBase = /** @class */ (function () {
    function RequestBase() {
    }
    RequestBase.prototype.createResponse = function () {
        return {};
    };
    // please override the getTypeName if the client request class name is mismatched with service
    RequestBase.prototype.getTypeName = function () {
        // default: set client Request class name as the service Request class name
        return this.getClassName();
    };
    RequestBase.prototype.getClassName = function () {
        return this.constructor.name;
    };
    return RequestBase;
}());
export { RequestBase };
/// used for all per folio based services , and filter by FolioId
var FolioRequestBase = /** @class */ (function (_super) {
    __extends(FolioRequestBase, _super);
    function FolioRequestBase() {
        var _this = _super.call(this) || this;
        _this.FolioId = null;
        return _this;
    }
    return FolioRequestBase;
}(RequestBase));
export { FolioRequestBase };
var RequestListBase = /** @class */ (function (_super) {
    __extends(RequestListBase, _super);
    function RequestListBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RequestListBase;
}(FolioRequestBase));
export { RequestListBase };
