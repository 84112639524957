import { Injectable } from '@angular/core';
import { PortalClientFactory } from '@_services/portal-client-factory';
import { GetCurrentTimeRequest } from '@app/app.dtos';

@Injectable({
  providedIn: 'root'
})
export class CurrentTimeService {

  constructor(private clientFactory: PortalClientFactory) { }

  async getServerTime(): Promise<number> {
    const client = this.clientFactory.createClient(true);
    var request = new GetCurrentTimeRequest();
    var response = await client.post(request);
    return response;
  }
}
