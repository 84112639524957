import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ResetPasswordService } from '../forgotten-password/services/resetpassword.service';
import { Alert } from '../model/alert';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public id: string;
  model: any = {};
  loading: boolean;
  alert: Alert;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resetPasswordService: ResetPasswordService
) { }

  ngOnInit() {
    const that = this;
    that.loading = true;
    this.route.params.subscribe(async params => {
      that.id = params['id'];
      try {
        await that.resetPasswordService.validatePasswordReset(that.id);
        that.loading = false;
      } catch (error) {
        if (error.responseStatus && error.responseStatus.errorCode) {
          if (error.responseStatus.errorCode == 'ArgumentException') {
            that.alert = new Alert(error.responseStatus.message, 'danger');
            return;
          }
        }
        // let default error handler handle unknown errors.
        throw error;
      }
    });
  }

  async resetPassword() {
    const that = this;
    that.alert = null;
    try {
      that.loading = true;
      const password = that.model.password || '';
      const confirmPassword = that.model.confirmPassword || '';
      let response = await that.resetPasswordService.resetPassword(that.id, password, confirmPassword);
      countDown(3, response.returnUrl);
    } catch (error) {
      that.loading = false;
      if (error.responseStatus && error.responseStatus.errorCode) {
        if (error.responseStatus.errorCode == 'ArgumentException') {
          that.alert = new Alert(error.responseStatus.message, 'danger');
          return;
        } else if (error.responseStatus.errors && error.responseStatus.errors.length > 0) {
          that.alert = new Alert(error.responseStatus.errors.map(err => err.message).join(', '), 'danger');
          return;
        }
      }
      // let default error handler handle unknown errors.
      throw error;
    }

    function countDown(i : number, returnUrl : string) {
      var int = setInterval(function () {
        that.alert = new Alert(`Your password has been changed. Redirect to login in ${i} ${(i > 1 ? 'seconds' : ' second')}`, 'success');
        i--;
        if (i < 0) {
          clearInterval(int);
          that.router.navigate(['/sign-in'], { queryParams: { returnUrl: returnUrl } });
        }
      }, 1000);
    }

  }

}
