var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { PortalClientFactory } from '@_services/portal-client-factory';
import { FolioRequestBase } from "./request-base";
import { PortalSession } from './portal-session';
import * as moment from "moment";
var UtilityService = /** @class */ (function () {
    function UtilityService(router, portalSession, clientFactory) {
        this.router = router;
        this.portalSession = portalSession;
        this.clientFactory = clientFactory;
    }
    UtilityService.prototype.refreshCurrentRoute = function () {
        var url = this.router.url;
        this.router.navigateByUrl(url);
    };
    UtilityService.prototype.reloadWholeApplication = function () {
        location.reload();
    };
    UtilityService.prototype.goHome = function () {
        this.router.navigate(['/']);
    };
    UtilityService.prototype.goProperty = function () {
        this.router.navigate(['/user/property']);
    };
    UtilityService.prototype.getCurrentAppVersion = function () {
        var client = this.clientFactory.createClient();
        var request = new GetCurrentAppVersion();
        if (client) {
            return client.get(request);
        }
        else {
            return null;
        }
    };
    UtilityService.prototype.sessionExpired = function () {
        if (!this.portalSession || !this.portalSession.session || !this.portalSession.session.ExpiresAt) {
            return true;
        }
        var diffInS = moment.duration(moment(this.portalSession.session.ExpiresAt).diff(moment())).asSeconds();
        return diffInS < 0;
    };
    return UtilityService;
}());
export { UtilityService };
// @Route("/api/settings/current-app-version")
var GetCurrentAppVersion = /** @class */ (function (_super) {
    __extends(GetCurrentAppVersion, _super);
    function GetCurrentAppVersion() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GetCurrentAppVersion.prototype.getTypeName = function () { return 'GetCurrentAppVersion'; };
    return GetCurrentAppVersion;
}(FolioRequestBase));
export { GetCurrentAppVersion };
var SettingResponse = /** @class */ (function () {
    function SettingResponse() {
    }
    return SettingResponse;
}());
export { SettingResponse };
