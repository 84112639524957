<form class="mx-auto" #form="ngForm" (ngSubmit)="save(form)" novalidate>
  <div class="modal-header">
    <h4 class="modal-title text-primary">
      <i class="icon icon-pencil icon-lg"></i>Change Email</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="!isLoaded">
    <div class="text-center pt-5">
      <strong class="text-info">
        <i class="icon icon-light-up fast-right-spinner"></i> Loading...
      </strong>
    </div>
  </div>
  <div class="modal-body" *ngIf="isLoaded">

    <form-field label="Current email">
      <input class="form-control" type="email" placeholder="Current email" name="CurrentEmail" required [(ngModel)]="dto.CurrentEmail">
    </form-field>

    <form-field label="New email">
      <input class="form-control" type="email" placeholder="New email" name="NewEmail" required [(ngModel)]="dto.NewEmail">
    </form-field>

    <form-field>
      Access to your property information is linked by email address. If you change your email it will remove access. PropertyMe recommends you contact your Agency first and then change your email.
    </form-field>
  </div>
  <ngb-alert *ngIf="alert" [type]="alert.type" (close)="alert = null">{{ alert.message }}</ngb-alert>
  <div class="modal-footer">
    <button class="btn btn-outline-primary float-right" type="button" (click)="activeModal.dismiss('cancel')">Cancel</button>
    <button [disabled]="isSaving || !form.valid" class="btn btn-outline-primary float-right" type="submit">Save</button>
  </div>
</form>
