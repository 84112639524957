<form class="mx-auto" #form="ngForm" (ngSubmit)="save(form)" novalidate>
  <div class="modal-header">
    <h4 class="modal-title text-primary">
      <i class="icon icon-pencil icon-lg"></i>Change Password</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="!isLoaded">
    <div class="text-center pt-5">
      <strong class="text-info">
        <i class="icon icon-light-up fast-right-spinner"></i> Loading...
      </strong>
    </div>
  </div>
  <div class="modal-body" *ngIf="isLoaded">

    <form-field label="Current password">
      <input class="form-control" type="password" placeholder="Current password" name="CurrentPassword" required [(ngModel)]="dto.CurrentPassword">
    </form-field>

    <form-field label="New password">
      <input class="form-control" type="password" placeholder="New password" name="NewPassword" minlength="10" maxlength="30" required [(ngModel)]="dto.NewPassword">
    </form-field>

    <form-field label="Confirm password">
      <input class="form-control" type="password" placeholder="Confirm password" name="ConfirmPassword" minlength="10" maxlength="30" required [(ngModel)]="confirmPassword">
    </form-field>

  </div>
  <ngb-alert *ngIf="alert" [type]="alert.type" (close)="alert = null">{{ alert.message }}</ngb-alert>
  <div class="modal-footer">
    <button class="btn btn-outline-primary float-right" type="button" (click)="activeModal.dismiss('cancel')">Cancel</button>
    <button [disabled]="isSaving || !form.valid" class="btn btn-outline-primary float-right" type="submit">Save</button>
  </div>
</form>
