<div *ngIf="!requireTwoFactor && !requireTwoFactorSetup" class="container h-100 w-100" id="page-container">
  <div class="row h-100">
    <div class="col-lg card align-items-center flex-column justify-content-between p-0">
      <div></div>
      <form class="mx-auto app-login-form" name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm"
            [ngClass]="{'was-validated': f.submitted}" novalidate>
        <app-loading [hidden]="!loading && !submitting"></app-loading>
        <div *ngIf="!loading">
          <div *ngIf="!customLogoUrl">
            <img class="non-retina" src="assets/img/brand.png" style="height:100px" alt="PropertyMe">
            <img class="retina" src="assets/img/brand-big.png" style="height:100px" alt="PropertyMe">
          </div>
          <div *ngIf="customLogoUrl">
            <h2 *ngIf="customName" class="text-center">{{customName}}</h2>
            <p class="text-center">
              <img src="{{customLogoUrl}}" class="custom-logo mx-auto mb-10" alt="">
            </p>
          </div>
          <div class="form-group">
            <input [disabled]="submitting || !isAllowedToSignIn" type="text" class="form-control" name="username"
                   [(ngModel)]="model.username"
                   #username="ngModel" required placeholder="Email"/>
            <div *ngIf="f.submitted && !username.valid" class="invalid-feedback">Email is required</div>
          </div>
          <div class="form-group mb-3">
            <input [disabled]="submitting || !isAllowedToSignIn" type="password" class="form-control" name="password"
                   [(ngModel)]="model.password"
                   #password="ngModel" required placeholder="Password"/>
            <div *ngIf="f.submitted && !password.valid" class="invalid-feedback">Password is required</div>
          </div>
          <ngb-alert *ngIf="alert" [type]="alert.type" (close)="alert = null" [dismissible]="true">
            <div class="card-text card-smaller-text  text-left">
              <i class="fas fa-exclamation-circle"></i> {{alert.message}}
            </div>
          </ngb-alert>
          <ngb-alert *ngIf="alert && isMemberPortalSignIn" type="info" (close)="alert = null">
            <div class="card-text card-smaller-text  text-left" style="display: flex;">
              <span><i class="far fa-lightbulb"></i></span>
              <span class="ml-1">Have you registered yet?
                <button type="button" name="register" class="btn btn-xs btn-outline-info" (click)="onConnectYourProperty()">Register
                </button></span>
            </div>
            <div class="card-text card-smaller-text text-left pt-3" style="display: flex;">
              <span><i class="far fa-lightbulb"></i></span>
              <span class="ml-1">Have you forgotten your password?
                <a routerLink="/forgotten-password" [queryParams]="{returnUrl: returnUrl}" class="btn btn-xs btn-outline-info">Reset Password
                </a></span>
            </div>
          </ngb-alert>
          <div class="mb-5 clearfix">
            <button *ngIf="isMemberPortalSignIn" class="btn btn-outline-primary float-left" type="button"
                    [disabled]="submitting"
                    (click)="onConnectYourProperty()">Connect Your Property
            </button>
            <button [disabled]="submitting || !isAllowedToSignIn" class="btn btn-primary float-right" type="submit">Sign
              In
            </button>
          </div>

          <ngb-alert *ngIf="isAgentLoginOnMemberPortal" type="danger" [dismissible]="false">
            <div class="card-text card-smaller-text  text-left">
              <i class="fas fa-exclamation-circle"></i> Your email isn't registered for owner or tenant access.
            </div>
          </ngb-alert>

          <div class="screen-login clearfix" *ngIf="isAgentLoginOnMemberPortal">
            <div class="card-smaller-text  text-left" style="display: flex;">
              <span class="ml-1">Have you registered?
                <a class="" href="{{memberPortalAppUrl}}sign-up">Register
                </a></span>
            </div>
            <div class="card-smaller-text text-left pt-3" style="display: flex;">
              <span class="ml-1">Forgot password?
                <a routerLink="/forgotten-password" [queryParams]="{returnUrl: returnUrl}" class="">Reset
                </a></span>
            </div>
            <div class="card-smaller-text text-left pt-3" style="display: flex;">
              <span class="ml-1">Are you a Property Manager?
                <a class="" href="{{managerAppUrl}}" >Sign in here
                </a></span>
            </div>
          </div>

          <div class="screen-login clearfix" *ngIf="!isAgentLoginOnMemberPortal">
            <a class="text-muted float-left" href="{{managerAppUrl}}" *ngIf="isMemberPortalSignIn">Property manager sign
              in</a>

            <a class="text-muted float-right" routerLink="/forgotten-password" *ngIf="isAllowedToSignIn"
               [queryParams]="{returnUrl: returnUrl}">Forgot password</a>
          </div>
        </div>
      </form>

      <footer class="w-100 p-0">
        <div *ngIf="browserWarningMessage" id="browserWarning">

          <p [innerHTML]="browserWarningMessage | safeHtml"></p>
          <p>
            For the best experience using this app, we recommend the latest versions of these browsers:
          </p>
          <a class="btn browserLink" target="_blank" href="https://www.google.com/chrome/browser/">
            <img class="browserIcon" src="assets/img/chrome-48.png">
            Google Chrome
          </a>
          <a class="btn browserLink" target="_blank" href="http://support.apple.com/downloads/#safari">
            <img class="browserIcon" src="assets/img/safari-48.png">
            Safari
          </a>
        </div>

        <div *ngIf="customLogoUrl" class="text-center">
          <img class="non-retina" src="assets/img/brand.png" style="width:100px" alt="PropertyMe">
          <img class="retina mx-auto" src="assets/img/brand-big.png" style="width:100px" alt="PropertyMe">
        </div>
        <copyright></copyright>
      </footer>
    </div>
    <div id="{{imageId}}" class="col-lg d-none d-xl-block d-lg-block">
    </div>
  </div>
</div>

<app-pin-verification *ngIf="requireTwoFactor"
                      [username]="model.username"
                      [password]="model.password"
                      [returnUrl]="returnUrl"
                      (back)="requireTwoFactor = !requireTwoFactor"></app-pin-verification>
<app-tfa-setup *ngIf="requireTwoFactorSetup"
               [username]="model.username"
               [password]="model.password"
               [returnUrl]="returnUrl"
               (back)="requireTwoFactorSetup = !requireTwoFactorSetup"></app-tfa-setup>
