var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@_services/index';
import { AppComponent } from '../app.component';
import { Alert } from '../model/alert';
import { BowserService } from 'ngx-bowser';
import { SignInService } from "@app/sign-in/services/sign-in.service";
import { environment } from "../../environments/environment";
;
var SignInComponent = /** @class */ (function () {
    function SignInComponent(route, router, authenticationService, rootComp, bowserService, signInService) {
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.rootComp = rootComp;
        this.bowserService = bowserService;
        this.signInService = signInService;
        this.model = {};
        this.loading = true;
        this.submitting = false;
        this.requireTwoFactor = false;
        this.requireTwoFactorSetup = false;
        this.alert = null;
        this.noLogo = false;
        this.browserWarningMessage = null;
        this.isMemberPortalSignIn = false;
        this.isAllowedToSignIn = true;
        this.managerAppUrl = environment.managerAppUrl;
        this.memberPortalAppUrl = environment.memberPortalUrl;
        this.isAgentLoginOnMemberPortal = false;
    }
    SignInComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var randomId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // reset login status
                        // this.authenticationService.logout();
                        // get return url from route parameters or default to '/'
                        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.route.snapshot.queryParams['ReturnUrl'] || '/';
                        randomId = Math.floor(Math.random() * 5) + 1;
                        this.imageId = 'image-container' + randomId;
                        this.browserWarningMessage = this.checkBrowser();
                        return [4 /*yield*/, this.load(this.returnUrl)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SignInComponent.prototype.load = function (returnUrl) {
        return __awaiter(this, void 0, void 0, function () {
            var that, response, error_1, msg;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        that = this;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.signInService.initSignIn(returnUrl)];
                    case 2:
                        response = _a.sent();
                        this.isMemberPortalSignIn = response.isMemberPortalLogin;
                        this.isAgentLoginOnMemberPortal = this.isMemberPortalSignIn && response.errorCode === 'AgentLoginOnMemberPortal';
                        this.customName = response.portfolioName;
                        this.customLogoUrl = response.customLogoUrl;
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        if (error_1.responseStatus) {
                            if (error_1.responseStatus.errorCode === 'ArgumentException') {
                                msg = error_1.responseStatus.message === 'Invalid UserName or Password'
                                    ? 'Email or password is invalid'
                                    : error_1.responseStatus.message;
                                that.alert = new Alert(msg, 'danger');
                                that.isAllowedToSignIn = false;
                                return [2 /*return*/];
                            }
                            else {
                                throw error_1;
                            }
                        }
                        // let default error handler handle unknown errors.
                        throw error_1;
                    case 4:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SignInComponent.prototype.checkBrowser = function () {
        if (!this.supportBrowser() && !this.bowserService.bowser.ipad) {
            return this.showFailMessage();
        }
        return null;
    };
    SignInComponent.prototype.supportBrowser = function () {
        return this.supportCSS() && this.supportPromise();
    };
    SignInComponent.prototype.supportPromise = function () {
        return typeof Promise !== "undefined" &&
            Promise.toString().indexOf("[native code]") !== -1;
    };
    ;
    SignInComponent.prototype.supportCSS = function () {
        var div = document.createElement('div');
        var vendors = 'Khtml Ms O Moz Webkit'.split(' ');
        var prop = 'webkitAlignContent';
        if (prop in div.style)
            return true;
        var len = vendors.length;
        prop = prop.replace(/^[a-z]/, function (val) {
            return val.toUpperCase();
        });
        while (len--) {
            if (vendors[len] + prop in div.style) {
                return true;
            }
        }
        return false;
    };
    SignInComponent.prototype.showFailMessage = function () {
        var name_ver = this.bowserService.bowser.name + " " + this.bowserService.bowser.version;
        var msg = "<strong><i class='icon-warning-sign warning'></i> Your browser is outdated. </strong>" + (name_ver.length > 1 ? " (" + name_ver + ")" : "") + "";
        return msg;
    };
    SignInComponent.prototype.login = function () {
        return __awaiter(this, void 0, void 0, function () {
            function countDown(i) {
                var int = setInterval(function () {
                    that.alert = new Alert("Your portfolio administrator has requested you to reset your account password. Redirect to Forgotten Password in " + i + " " + (i > 1 ? 'seconds' : 'second') + ".", 'warning');
                    i--;
                    if (i < 0) {
                        clearInterval(int);
                        that.router.navigate(['/forgotten-password'], { preserveQueryParams: true });
                    }
                }, 1000);
            }
            var that, userName, error_2, msg;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        that = this;
                        that.alert = null;
                        that.submitting = true;
                        userName = that.model.username || '';
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, that.authenticationService.login(userName.trim(), that.model.password, that.returnUrl)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_2 = _a.sent();
                        if (error_2.responseStatus) {
                            switch (error_2.responseStatus.errorCode) {
                                case 'RequireTwoFactorSecret':
                                    that.requireTwoFactorSetup = true;
                                    return [2 /*return*/];
                                case 'RequireOneTimePinCode':
                                    that.requireTwoFactor = true;
                                    return [2 /*return*/];
                                case 'RequireResetPassword':
                                    countDown(5);
                                    return [2 /*return*/];
                                case 'ServerException':
                                    throw error_2;
                                default:
                                    msg = error_2.responseStatus.message === 'Invalid UserName or Password'
                                        ? 'Email or password is invalid'
                                        : error_2.responseStatus.message;
                                    that.alert = new Alert(msg, 'danger');
                                    return [2 /*return*/];
                            }
                        }
                        // let default error handler handle unknown errors.
                        throw error_2;
                    case 4:
                        that.submitting = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SignInComponent.prototype.onConnectYourProperty = function () {
        this.submitting = true;
        window.location.href = environment.memberPortalUrl + 'sign-up';
    };
    return SignInComponent;
}());
export { SignInComponent };
